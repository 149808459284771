<template>
  <div class="footerBG">
    <div class="">
      <v-card elevation="8">
        <div class="card-body p-4">
          <div class="pt-2 form-login">
            <div class="form-group">
              <p v-if="loginCompra" class="h5">
                Crie sua conta para continuar comprando
              </p>
              <p class="font titulologin">
                Insira seus dados para criar sua conta! :)
              </p>
            </div>
            <div class="row">
              <div class="col-lg-12">
                <div class="form-group mb-3">
                  <v-text-field
                    color="black"
                    v-model="nome"
                    type="'text'"
                    label="Nome Completo"
                    :rules="[rules.required]"
                    placeholder="Digite seu nome completo"
                    id="NomeCompleto"
                    required
                    clearable
                  ></v-text-field>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="form-group mb-3">
                  <v-text-field
                    color="black"
                    v-model="nascimento"
                    :type="'date'"
                    label=""
                    id="nascimentoUsuario"
                    :rules="[rules.required]"
                    placeholder="Insira sua data de nascimento"
                  ></v-text-field>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="form-group mb-3">
                  <v-text-field
                    color="black"
                    v-model="email"
                    type="email"
                    label="Email"
                    placeholder="Digite seu email"
                    id="EmailUsuario"
                    :rules="[rules.required]"
                    clearable
                  ></v-text-field>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="form-group mb-3">
                  <v-text-field
                    color="black"
                    v-model="cpf"
                    :type="'text'"
                    label="Digite seu CPF"
                    id="CPF"
                    placeholder="000.000.000-00"
                    :rules="[rules.required]"
                    clearable
                  ></v-text-field>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="form-group mb-3">
                  <v-text-field
                    color="black"
                    v-mask="'(##) #####-####'"
                    v-model="telefone"
                    :type="'text'"
                    label="Digite seu telefone"
                    id="Telefone"
                    placeholder="(99) 99999-9999"
                    :rules="[rules.required]"
                    clearable
                  ></v-text-field>
                </div>
              </div>

              <div class="col-lg-6">
                <div class="form-group mb-3">
                  <v-text-field
                    color="black"
                    v-model="senha"
                    :append-icon="mostrarSenha ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="mostrarSenha ? 'text' : 'password'"
                    label="Senha"
                    id="SenhaUsuario"
                    placeholder="Digite sua senha"
                    :rules="[rules.required]"
                    @click:append="mostrarSenha = !mostrarSenha"
                  ></v-text-field>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="form-group mb-3">
                  <v-text-field
                    color="black"
                    v-model="repsenha"
                    :append-icon="mostrarSenha ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="mostrarSenha ? 'text' : 'password'"
                    label="Repetir a senha"
                    id="SenhaUsuario"
                    placeholder="Repetir a senha"
                    @click:append="mostrarSenha = !mostrarSenha"
                  ></v-text-field>
                </div>
              </div>

              <div class="col-lg-12">
                <div class="font text-right dec">
                  <template>
                    <v-container fluid>
                      <v-checkbox
                        class="termo-politica"
                        color="appColor-primary"
                        v-model="termoPolitica"
                      >
                        <template v-slot:label>
                          <p class="font text-justify polit">
                            Declaro que li e estou de acordo com as tarifas,
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on }">
                                <a
                                  class="links"
                                  target="_blank"
                                  href=""
                                  @click.stop
                                  v-on="on"
                                >
                                  condições de compra
                                </a>
                              </template>
                              Abrir condições em outra página
                            </v-tooltip>
                            e
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on }">
                                <a
                                  class="links"
                                  target="_blank"
                                  href=""
                                  @click.stop
                                  v-on="on"
                                >
                                  políticas de cancelamento
                                </a>
                              </template>
                              Abrir Politicas de cancelamento em outra página
                            </v-tooltip>
                            da Enseada Turismo.
                          </p>
                        </template>
                      </v-checkbox>
                      <p class="font text-justify polit theme--light v-label">
                        Para saber mais sobre coleta, utilização e proteção de
                        seus dados pessoais, leia nossa Política de Privacidade.
                      </p>
                    </v-container>
                  </template>
                </div>
              </div>
            </div>

            <v-alert
              v-for="erro in Object.keys(erros)"
              :key="erro"
              dense
              outlined
              type="error"
            >
              <strong>{{ erro }}: </strong> {{ erros[erro] }}
            </v-alert>

            <div class="form-group mb-0 text-center">
              <v-btn
                class="appBG-primary px-8 w-100"
                :disabled="!termoPolitica"
                @click.prevent="criarConta"
              >
                <span class="appColor-white" v-if="!loading">
                  Criar conta
                </span>
                <v-progress-circular
                  v-else
                  indeterminate
                  color="white"
                ></v-progress-circular>
              </v-btn>
            </div>
            <div class="form-group mt-8 mb-0 text-center">
              <v-btn text @click="home">
                <span class="appColor-primary">
                  Voltar para a tela inicial
                </span>
              </v-btn>
            </div>
          </div>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
import Mixin from "@/mixins/vuex_mixin.js";
import UsuarioService from "@/services/usuario_service.js";

export default {
  mixins: [Mixin],
  components: {},
  data: () => ({
    nascimento: "",
    nome: "",
    cpf: "",
    telefone: "",
    email: "",
    senha: "",
    repsenha: "",
    termoPolitica: false,
    loading: false,
    mostrarSenha: false,
    erros: [],
    rules: {
      required: (value) => !!value || "Campo obrigatório",
    },
    loginCompra: false,
  }),

  beforeMount() {
    /*if (this.$store.state.token) {
      this.$router.push('/')
    }

    if (this.$route.params.loginCompra) {
      this.loginCompra = true
    }*/
  },

  methods: {
    home() {
      this.$router.replace("/");
    },
    criarConta() {
      const usuario = {
        nome: this.nome,
        email: this.email,
        cpf: this.cpf,
        telefone_1: this.telefone,
        senha: this.senha,
        data_nascimento: this.nascimento.split("/").reverse().join("-"),
      };

      const validationUser = Object.values(usuario).filter(
        (campo) => campo.length == 0
      );

      if (validationUser.length) {
        this.$_ACTIONS_showSnackbarMessage({
          message: "Preencha os dados corretamente",
          color: "error",
        });
        return;
      }

      const usuarioService = new UsuarioService();
      this.loading = true;
      usuarioService.register(
        {
          onSucess: (status) => (body) => {
            if (status === 200) {
              this.$_ACTIONS_showSnackbarMessage({
                message: "Usuario criado com sucesso! Por favor, faça login.",
                color: "sucess",
              });

              if (this.loginCompra) {
                this.$router.push({
                  name: "login",
                  params: { loginCompra: this.loginCompra },
                });
              } else {
                this.$router.replace("login");
              }

              this.limparCapos();
            } else {
              this.$_ACTIONS_showSnackbarMessage({
                message:
                  body.message || this.$global.messages.internalServerError,
                color: "error",
              });
              this.erros = body.errors;
            }
          },
          onError: (error) => {
            console.error(error);
            this.$_ACTIONS_showSnackbarMessage({
              message: this.$global.messages.internalServerError,
              color: "error",
            });
          },
          onEnd: () => {
            this.loading = false;
          },
        },
        usuario
      );
    },
    voltarLogin() {
      this.$router.replace("login");
    },
    limparCapos() {
      this.nascimento = "";
      this.nome = "";
      this.cpf = "";
      this.telefone = "";
      this.email = "";
      this.senha = "";
      this.repsenha = "";
      this.erros = [];
    },
  },
};
</script>
<style scoped>
.dec {
  margin-top: -4rem;
}
.polit {
  line-height: 18px;
  font-weight: 500;
}
@media (max-width: 600px) {
  .p-5 {
    padding: 40px !important;
  }
  .margintop-menu {
    margin-top: 2rem;
  }
  .mb-3 {
    margin-bottom: 0 !important;
  }
  .v-text-field {
    padding-top: 0px;
    margin-top: 0px;
  }
  .check {
    font-size: 15px !important;
  }
}
</style>
