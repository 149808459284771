<template>
  <div>
    <div>
      <header-geral />
    </div>
    <img class="pedidos" src="@/assets/images/bg-meuspedidos.png" />
    <div class="menu container">
      <Menu_loja />
      <h2 class="appColor-white titles text-left">MINHAS VIAGENS</h2>
    </div>
    <div class="p-4 mt-12 container">
      <div class="text-right">
        <v-btn @click="logout">Sair</v-btn>
      </div>
      <nav>
        <div class="nav nav-tabs" id="nav-tab" role="tablist">
          <button
            class="nav-link active"
            id="nav-home-tab"
            data-bs-toggle="tab"
            data-bs-target="#nav-home"
            type="button"
            role="tab"
            aria-controls="nav-home"
            aria-selected="true"
          >
            Viagens Agendadas
          </button>
          <button
            class="nav-link"
            id="nav-profile-tab"
            data-bs-toggle="tab"
            data-bs-target="#nav-profile"
            type="button"
            role="tab"
            aria-controls="nav-profile"
            aria-selected="false"
          >
            Minhas Compras
          </button>
          <button
            class="nav-link"
            id="nav-contact-tab"
            data-bs-toggle="tab"
            data-bs-target="#nav-contact"
            type="button"
            role="tab"
            aria-controls="nav-contact"
            aria-selected="false"
          >
            Cancelados
          </button>
        </div>
      </nav>
      <div class="tab-content" id="nav-tabContent">
        <div
          class="tab-pane fade show active text-left"
          id="nav-home"
          role="tabpanel"
          aria-labelledby="nav-home-tab"
        >
          <div class="rounded-lg outlined">
            <div class="row container">
              <div class="col-12" v-if="pedidos_agendados.length > 0">
                <v-card
                  v-for="carrinho in pedidos_agendados"
                  :key="carrinho.carrinho_id"
                  class="col-12 d-flex flex-column mb-4 rounded-t-lg somb"
                  elevation="0"
                  outlined
                >
                  <v-card-text
                    class="row"
                    v-for="pacote in carrinho.pacotes"
                    :key="pacote.pacote_id"
                  >
                    <div class="col-12 mr-5">
                      <h4 class="appColor-blue font">
                        {{ pacote.pacote_nome }}
                      </h4>
                      <p>
                        {{
                          pacote.produtos
                            .reduce((acc, c) => acc + ` - ${c.produto}`, "")
                            .substr(2)
                        }}
                      </p>
                    </div>

                    <div class="col-6 cardb ml-5">
                      <div class="d-flex">
                        <div class="col d-flex">
                          <h4 class="appColor-blue titles price">
                            R${{
                              parseFloat(pacote.preco)
                                | money({
                                  prefix: "",
                                  suffix: "",
                                  thousands: ".",
                                  decimal: ",",
                                  precision: 2,
                                })
                            }}
                          </h4>
                        </div>
                        <div class="">
                          <span class="list-group-item"
                            >Dia {{ pacote.agenda | formatData }}</span
                          >

                          <BtnVoucher
                            :pacoteId="pacote.pacote_id"
                            :carrinhoId="carrinho.carrinho_id"
                            :agenda="pacote.agenda.split('-').join('/')"
                            :produtosPacote="pacote.produtos"
                          />
                        </div>
                      </div>
                    </div>
                  </v-card-text>
                </v-card>
              </div>
              <div v-else>
                <h3 class="font appColor-blue mb-10">
                  Parece que você ainda não possui nenhuma viagem.
                </h3>
                <p class="font appColor-blue mb-10">
                  Escolha agora sua viagem com a Enseada Turismo!
                </p>
                <!-- <a class="appBG-primary px-9 appColor-white" href="../detalhes-categoria">Pesquisar por Viagens</a> -->
                <v-btn
                  class="appBG-primary px-9 appColor-white"
                  href="../detalhes-categoria"
                  >Pesquisar por Viagens</v-btn
                >
                <!-- <v-btn class="appBG-primary px-9 appColor-white" @click="logout">Pesquisar por Viagens</v-btn>  -->
              </div>
            </div>
          </div>
        </div>
        <div
          class="tab-pane fade"
          id="nav-profile"
          role="tabpanel"
          aria-labelledby="nav-profile-tab"
        >
          <div class="rounded-lg outlined">
            <div class="row container">
              <div class="col-12" v-if="pedidos.length > 0">
                <v-card
                  v-for="pedido in pedidos"
                  :key="pedido.pedido_id"
                  class="col-12 d-flex flex-column mb-4 rounded-t-lg somb"
                  elevation="0"
                  outlined
                >
                  <v-card-text
                    class="row"
                    v-for="pacote in pedido.pacotes"
                    :key="pacote.pacote_id"
                  >
                    <div class="col-12" v-if="pacote.pacote_ativo == 1">
                      <div class="col-12">
                        <h4 class="appColor-blue font">
                          {{ pacote.pacote_nome }}
                        </h4>
                        <p>
                          {{
                            pacote.produtos
                              .reduce(
                                (acc, c) => acc + ` - ${c.produto_nome}`,
                                ""
                              )
                              .substr(2)
                          }}
                        </p>
                      </div>

                      <div class="col-12 cardb">
                        <div class="d-flex">
                          <div class="col d-flex">
                            <h4 class="appColor-blue titles price">
                              R${{
                                parseFloat(pacote.preco)
                                  | money({
                                    prefix: "",
                                    suffix: "",
                                    thousands: ".",
                                    decimal: ",",
                                    precision: 2,
                                  })
                              }}
                            </h4>
                          </div>
                          <div class="col">
                            <span class="list-group-item"
                              >Comprado em
                              {{ pedido.compra_data | formatData }}</span
                            >
                            <div class="list-group-item">
                              <h4 class="text-center">
                                Status:
                                <span class="font-weight-light">{{
                                  pedido.compra_status == "awaiting_table"
                                    ? "Aguardando aprovação"
                                    : pedido.compra_status.toLowerCase() ==
                                      "confirmado"
                                    ? "Aprovado"
                                    : pedido.compra_status
                                }}</span>
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </v-card-text>
                </v-card>
              </div>
              <h3 class="grey--text" v-else>Nenhuma compra realizada</h3>
            </div>
          </div>
        </div>
        <div
          class="tab-pane fade"
          id="nav-contact"
          role="tabpanel"
          aria-labelledby="nav-contact-tab"
        >
          <div class="container rounded-lg outlined">
            <div class="row container">
              <div class="col-12" v-if="pedidos.length > 0">
                <v-card
                  v-for="pedido in pedidos"
                  :key="pedido.pedido_id"
                  class="col-12 d-flex flex-column mb-4 rounded-t-lg somb"
                  elevation="0"
                  outlined
                >
                  <v-card-text
                    class="row"
                    v-for="pacote in pedido.pacotes"
                    :key="pacote.pacote_id"
                  >
                    <div class="col-12" v-if="pacote.pacote_ativo == 0">
                      <div>
                        <h4 class="appColor-blue font">
                          {{ pacote.pacote_nome }}
                        </h4>
                        <p class="col-10">
                          {{
                            pacote.produtos
                              .reduce(
                                (acc, c) => acc + ` - ${c.produto_nome}`,
                                ""
                              )
                              .substr(2)
                          }}
                        </p>
                      </div>

                      <div class="col-6 cardb">
                        <div class="d-flex">
                          <div class="col d-flex">
                            <h4 class="appColor-blue titles price">
                              R${{
                                parseFloat(pacote.preco)
                                  | money({
                                    prefix: "",
                                    suffix: "",
                                    thousands: ".",
                                    decimal: ",",
                                    precision: 2,
                                  })
                              }}
                            </h4>
                          </div>
                          <div class="col pb-5">
                            <span class="list-group-item mb-5"
                              >Comprado em
                              {{ pedido.compra_data | formatData }}</span
                            >
                            <span class="list-group-item">
                              Pedido cancelado
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </v-card-text>
                </v-card>
              </div>
              <h3 class="grey--text" v-else>Nenhum pedido cancelado</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-16">
      <footer-app />
    </div>
  </div>
</template>

<script>
import VuexUsuarioMixin from "@/mixins/vuex_usuario_mixin.js";
import VuexTokenMixin from "@/mixins/vuex_token_mixin.js";
import headerGeral from "@/components/template/header/header_geral.vue";
import footerApp from "@/components/template/footer_app.vue";
import CarrinhoMixin from "@/mixins/carrinho_mixin.js";
import CompraService from "@/services/compras_service";
import ViagensAgendadas from "@/services/viagensAgendadas_service.js";
import Menu_loja from "../components/template/header/navbar/menu_loja.vue";
import BtnVoucher from "../components/pdf/btnVoucher.vue";

export default {
  components: {
    headerGeral,
    footerApp,
    Menu_loja,
    BtnVoucher,
  },

  mixins: [VuexUsuarioMixin, VuexTokenMixin, CarrinhoMixin],

  beforeMount() {
    if (!this.$store.state.token) {
      this.$router.push("/");
    }

    this.atualizarCarrinhoCompra();
  },

  watch: {
    carrinhoProdutos: {
      handler: function (newcarrinho) {
        console.log(newcarrinho);
        this.atualizarTotalCompra(newcarrinho);
        this.atualizarquantidadeCompra(newcarrinho);
      },
      deep: true,
    },
  },

  methods: {
    logout() {
      this.$_ACTIONS_setUsuario({});
      this.$_ACTIONS_setToken("");
      this.$router.replace("login");
    },

    addToCartBatch(pedido) {
      pedido.pacotes.map((p) => {
        let carrinho = {
          titulo: p.pacote_nome,
          // valorTotal: parseFloat(p.preco) * p.quantidade,
          valorUnitario: parseFloat(p.preco),
          quantidade: parseInt(p.quantidade),
          id: p.pacote_id,
        };

        this.$_ACTIONS_carrinho(carrinho);
      });
    },
  },

  filters: {
    statusPagamento(status) {
      const stts = {
        waiting: "aguardando",
        paid: "pago",
        declined: "recusado",
      };

      return stts[status.toLowerCase()];
    },

    fillLeft: function (value = "", qtd = 0) {
      let v = value.toString();
      if (v.length < qtd) {
        return "0".repeat(qtd - v.length) + v;
      }

      return v;
    },

    formatData: function (data) {
      return data.substr(0, 10).split("-").reverse().join("/");
    },
  },

  mounted() {
    const compraService = new CompraService();
    const viagensAgendadas = new ViagensAgendadas();

    compraService.getAll({
      onSucess: (status) => (body) => {
        if (status === 200) {
          this.pedidos = body.data.compras;
          console.log(this.pedidos);
        }

        if (status === 400 || status === 500) {
          this.$router.push({ name: "login" });
        }
      },
      onError: () => {},
      onEnd: () => {
        this.loading = false;
      },
    });

    viagensAgendadas.getAll({
      onSucess: (status) => (body) => {
        if (status === 200) {
          this.pedidos_agendados = body.data.compras;
          console.log(this.pedidos_agendados);
        }

        if (status === 400 || status === 500) {
          this.$router.push({ name: "login" });
        }
      },
      onError: () => {},
      onEnd: () => {
        this.loading = false;
      },
    });
  },

  // attachToCard(compra) {
  //   compra.pacotes.forEach(pacote => {
  //     //
  //   })
  // },

  data: () => ({
    pedidos: [],
    pedidos_agendados: [],
    carrinhoProdutos: [],
    totalCompra: 0,
    quantidadeCompra: 0,
  }),
};
</script>

<style scoped>
.nav-tabs .nav-link.active,
.nav-tabs:focus {
  border-color: transparent transparent #fac301;
  border-bottom: 3px solid #fac301;
  color: #2b3a8f;
  font-weight: 600;
}
.nav-link:hover,
.nav-tabs {
  border-color: transparent;
  color: #2b3a8f;
  font-weight: 600;
}
.tab-pane {
  width: 90vw;
}
.titles {
  margin-left: 29px;
  font-size: 29px;
}
small {
  text-align: left;
  font-weight: 600;
  color: #757575;
}
.card-img-wrapper {
  max-width: 128px;
  border-radius: 5px;
}
.card-rel {
  display: flex;
}
h4,
p {
  text-align: left;
}
.detalhes-pedido {
  font-weight: bold;
  cursor: pointer;
  font-size: 14px;
}

.comprar-button {
  text-transform: unset;
  font-weight: bold;
}
@media (max-width: 600px) {
  .labels {
    display: block !important;
  }
  .help {
    justify-content: space-around;
  }
  .v-application .d-flex {
    display: block !important;
  }
  .price {
    font-size: 25px !important;
    width: 200px;
    margin: auto;
  }
}
</style>
