<template>
  <div>
    <nav class="navbar navbar-expand-lg navbar-light">
      <div class="container-fluid">
        <a href="/">
          <img src="@/assets/images/logo-enseada.png" alt="..." />
        </a>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse col-0" id="navbarSupportedContent">
          <ul
            class="navbar-nav me-auto mb-0 mb-lg-0 menu-home font appColor-white"
          >
            <li class="">
              <a aria-current="page" href="../promo-detalhes-categoria"
                >Promoções</a
              >
            </li>
            <li class="lmenu">
              <a href="../detalhes-categoria">Passeios</a>
            </li>
            <li class="lmenu">
              <a href="#enseada">A Enseada</a>
            </li>
            <li class="lmenu">
              <a href="#novidades">Novidades</a>
            </li>
            <li v-if="isGuest()" class="lmenu">
              <a href="login">Entre ou Cadastre-se</a>
            </li>
            <li v-if="!isGuest()" class="lmenu dropdown">
              <a
                class="dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Minha Conta
              </a>
              <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                <li>
                  <a class="dropdown-item" @click="alterar_dados"
                    >Alterar Dados</a
                  >
                </li>
                <li>
                  <a class="dropdown-item" @click="meus_pedidos"
                    >Minhas Viagens</a
                  >
                </li>
                <li><a class="dropdown-item" @click="logout">Sair</a></li>
              </ul>
            </li>
            <li class="">
              <v-btn elevation="0" @click.stop="abrirCarrinho = !abrirCarrinho">
                <!-- <v-btn elevation="0" @click.stop="teste"> -->
                <v-badge color="#2b3a8f" :content="quantidade_produtos">
                  <v-icon class="appColor-blue icon-font-size"
                    >mdi-basket</v-icon
                  >
                </v-badge>
              </v-btn>
            </li>
          </ul>
        </div>
      </div>
    </nav>
    <v-navigation-drawer
      class="modal-carrinho"
      v-model="abrirCarrinho"
      fixed
      right
      temporary
    >
      <carrinho-template @carrinhoModal="abrirCarrinho = !abrirCarrinho" />
    </v-navigation-drawer>
  </div>
</template>

<script>
//import AuthService from "@/services/auth_service";
import Mixin from "@/mixins/vuex_mixin.js";

export default {
  mixins: [Mixin],
  data: () => ({
    abrirCarrinho: false,
  }),
  components: {
    carrinhoTemplate: () =>
      import("@/components/carrinho_modal/carrinho_template.vue"),
  },
  computed: {
    quantidade_produtos() {
      return this.$store.state.carrinho.length || 0;
    },

    user: () => this.$store.state.usuario,
  },
  watch: {
    "$store.state.carrinho": {
      handler: "showCarrinho",
      deep: true,
    },
  },
  methods: {
    showCarrinho() {
      this.abrirCarrinho = true;
    },
    logout() {
      this.$_ACTIONS_setUsuario({});
      this.$_ACTIONS_setToken("");
      this.$router.replace("login");
    },
    alterar_dados() {
      this.$router.replace("alterar-dados");
    },
    meus_pedidos() {
      this.$router.replace("/meus-pedidos");
    },
    isGuest() {
      return !this.$store.state.token;
    },
    autenticarLogin() {
      let token = this.$_GETTERS_token.length;
      if (token == "") {
        return this.$router.replace("login");
      } else {
        return this.$router.replace("meus-pedidos");
      }
    },
  },
};
</script>

<style scoped>
li:hover ul {
  display: block;
}
.dropdown-item {
  color: #000 !important;
  background: #fff;
  text-align: right;
}
.menu-home {
  display: flex;
  justify-content: space-between;
  list-style-type: none;
  margin-top: 1rem;
  width: 100%;
}
.menu-home a {
  color: #fff;
  font-weight: 500;
}
.navbar-toggler {
  background-color: #fff !important;
}
.navbar {
  padding: 0rem;
}

@media (max-width: 600px) {
  ul {
    padding: 0;
  }
  ul li {
    padding: 15px;
    font-size: 20px;
  }
  .menu-home {
    display: block;
    margin-top: 0rem;
    justify-content: center;
  }
  .menu-home .it {
    margin: 1% 0% 1% 0%;
  }
  .bannercall {
    text-align: center;
    background-size: cover;
    padding: 3rem 3rem 3rem 3rem;
  }
  .d-flex {
    display: block !important;
  }
  #navbarSupportedContent {
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #fac301;
    margin: 20px auto;
    padding: 20px;
    width: 500px;
    border-radius: 10px;
  }
  .bannercall2 {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
  }
}
</style>
