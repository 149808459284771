<template>
  <div>
    <v-footer class="footerBG pt-14 pb-16" padless>
      <div class="container">
        <div class="row">
          <div class="col-lg-3">
            <div class="logofooter text-left">
              <!-- <router-link to="/">-->
              <h3 class="text-uppercase appColor-primary text-left">
                A enseada
              </h3>
              <a href="#enseada">Sobre</a>
              <!--</router-link>-->
            </div>
          </div>
          <div class="col-lg-3">
            <h3 class="text-uppercase appColor-primary text-left">Pacotes</h3>
            <ul class="itens-footer">
              <li>
                <a href="#pacotes">Conheça nossos pacotes</a>
              </li>
            </ul>
          </div>
          <div class="col-lg-3">
            <h3 class="text-uppercase appColor-primary text-left">Novidades</h3>
            <div class="row">
              <div class="col-lg-5">
                <ul class="itens-footer">
                  <li>
                    <a
                      href="https://www.instagram.com/enseada.turismo/"
                      target="_blank"
                      >Instagram</a
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-lg-3">
            <h3 class="text-uppercase appColor-primary text-left">
              Próxima viagem
            </h3>
            <div class="row">
              <div class="col-lg-5">
                <ul class="itens-footer">
                  <li>
                    <a href="../login">Cadastro</a>
                  </li>
                  <li>
                    <a href="../login">Login</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-9">
            <h3 class="text-uppercase appColor-primary text-left">
              Siga nossas redes sociais
            </h3>
            <div class="d-flex align-items-center">
              <a
                href="https://www.facebook.com/pages/Enseada-Turismo-Fortaleza/155458398139512"
                target="_blank"
                ><v-icon class="appColor-primary mr-2">mdi-facebook</v-icon></a
              >
              <a
                href="https://www.instagram.com/enseada.turismo/"
                target="_blank"
                ><v-icon class="appColor-primary mr-2">mdi-instagram</v-icon></a
              >
            </div>
          </div>
          <div class="col-lg-3 text-left">
            <p class="appColor-primary">
              <b>CNPJ: <br />Enseada Agencia de Viagens LTDA </b> <br />
              08.067.903-0001-00
            </p>
          </div>
        </div>
        <div class="wpp">
          <a
            href="https://api.whatsapp.com/send?phone=5585996081221&text=Ol%C3%A1%2C%20vi%20o%20site%20e%20quero%20mais%20infoma%C3%A7%C3%B5es%20sobre%20os%20pacotes%20de%20turismo!"
            target="_blank"
          >
            <v-img src="@/assets/images/gif-wpp.gif"> </v-img>
          </a>
        </div>
      </div>
    </v-footer>
    <div class="subfooter d-flex justify-content-center">
      <a href="https://prolins.com.br" target="_blank">
        <v-img
          src="@/assets/images/logo-prolins-branco.png"
          max-width="140px"
        ></v-img>
      </a>
    </div>
  </div>
</template>

<script>
import UnidadeService from "../../services/unidades_service";

export default {
  data: () => ({
    unidades: [],
    unidade_atual: 0,
  }),

  beforeMount() {
    this.getUnidades();

    setInterval(() => {
      if (this.unidade_atual + 1 == this.unidades.length) {
        this.unidade_atual = 0;
      } else {
        this.unidade_atual++;
      }
    }, 1500);
  },

  methods: {
    getUnidades() {
      this.loading = true;
      const unidadeService = new UnidadeService();
      this.unidades = [];
      const onSucess = (status) => (body) => {
        if (status === 200) {
          this.unidades = body.data.unidades;
        } else {
          console.log(body);
        }
      };
      const onError = (error) => {
        console.log(error);
      };
      const onEnd = () => {
        this.loading = false;
      };

      unidadeService.getList({ onSucess, onError, onEnd });
    },
  },
};
</script>

<style scoped>
h3,
.appColor-primary,
a {
  color: #fff !important;
}
i {
  font-size: 48px !important;
}
.subfooter {
  padding: 2rem 0;
  background-color: #142166;
}

.itens-footer {
  list-style: none;
  padding-left: 0;
}

.itens-footer li {
  text-align: left;
}

.itens-footer li a {
  color: #000;
}
</style>
