<template>
  <div>
    <div>
      <header-geral />
    </div>
    <img class="pedidos" src="@/assets/images/bg-meuspedidos.png">
    <div class="menu container">
      <Menu_loja />
      <h2 class="appColor-white titles text-left">MINHA CONTA</h2>
    </div>

    <div class="p-4 mt-12">
      <div class="p-2 mt-2"></div>
    </div>
    <h1>Dados cadastrais</h1>

    <div class="container p-2 mb-2">
      <v-card class="p-4">
        <v-row>
          <v-col cols="6">
            <v-text-field
              label="Nome"
              :value="usuario.nome"
              disabled
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              label="E-mail"
              :value="usuario.email"
              disabled
            ></v-text-field>
          </v-col>

          <v-col cols="6">
            <v-text-field
              label="CPF"
              :value="usuario.cpf"
              disabled
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              label="Data de nascimento"
              :value="usuario.data_nascimento"
              disabled
            ></v-text-field>
          </v-col>

          <v-col cols="6">
            <v-text-field
              label="Telefone 1"
              :value="usuario.telefone_1"
              disabled
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              label="Telefone 2"
              :value="usuario.telefone_2"
              disabled
            ></v-text-field>
          </v-col>
        </v-row>

        <h3>Alterar senha</h3>
        <form>
          <v-row>
            <v-col cols="4">
              <v-text-field
                v-model="senha"
                type="password"
                label="Senha atual"
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                v-model="nova_senha"
                type="password"
                label="Nova senha"
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                v-model="confirma_senha"
                type="password"
                label="Confirmar senha"
              ></v-text-field>
            </v-col>
          </v-row>
        </form>

        <v-row>
          <v-btn class="appBG-primary white--text mb-4" @click="alterarSenha"
            >Alterar senha</v-btn
          >
        </v-row>

        <v-row>
          <v-btn class="mb-8" @click="logout">Sair</v-btn>
        </v-row>
      </v-card>
    </div>

    <div class="mt-16">
      <footer-app />
    </div>
  </div>
</template>

<script>
import Mixin from "@/mixins/vuex_mixin.js";
import VuexUsuarioMixin from "@/mixins/vuex_usuario_mixin.js";
import VuexTokenMixin from "@/mixins/vuex_token_mixin.js";
import headerGeral from "@/components/template/header/header_geral.vue";
import footerApp from "@/components/template/footer_app.vue";
import UsuarioService from "@/services/usuario_service.js";
import Menu_loja from '../components/template/header/navbar/menu_loja.vue';

export default {
  components: {
    headerGeral,
    footerApp,
    Menu_loja,
  },

  data: () => ({
    usuario: {},
    nova_senha: null,
    confirma_senha: null,
    senha: null,
  }),

  mixins: [VuexUsuarioMixin, VuexTokenMixin, Mixin],

  beforeMount() {
    const usuarioService = new UsuarioService();
    usuarioService.getUsuarioData(
      {
        onSucess: (status) => (body) => {
          if (status === 200) {
            this.usuario = body.data.clientes[0];
            if (this.usuario.data_nascimento) {
              this.usuario.data_nascimento = this.usuario.data_nascimento
                .split(" ")[0]
                .split("-")
                .reverse()
                .join("/");
            }
          }
        },
        onError: () => {
          //
        },
        onEnd: () => {
          //
        },
      },
      this.$store.state.usuario.id
    );
  },

  methods: {
    logout() {
      this.$_ACTIONS_setUsuario({});
      this.$_ACTIONS_setToken("");
      this.$router.replace("login");
    },

    alterarSenha() {
      const data = {
        senha_atual: this.senha,
        nova_senha: this.nova_senha,
      };

      const usuarioService = new UsuarioService();

      if (this.confirma_senha == this.nova_senha) {
        usuarioService.updateUsuarioSenha(
          {
            onSucess: (status) => (body) => {
              if (status === 200) {
                this.$_ACTIONS_showSnackbarMessage({
                  message: "Senha alterada com sucesso",
                  color: "sucess",
                });
              } else {
                this.$_ACTIONS_showSnackbarMessage({
                  message: body.message,
                  color: "error",
                });
              }
            },
            onError: (error) => {
              this.$_ACTIONS_showSnackbarMessage({
                message: error.message,
                color: "error",
              });
            },
            onEnd: () => {
              //
            },
          },
          this.$store.state.usuario.id,
          data
        );
      } else {
        this.$_ACTIONS_showSnackbarMessage({
          message: "Senhas não coincidem",
          color: "error",
        });
      }
    },
  },
};
</script>