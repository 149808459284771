<template>
  <div>
    <button
      type="button"
      class="btn btn-primary"
      @click="emitirVoucher(pacoteId, carrinhoId, agenda)"
    >
      <span v-if="!loading"> Emitir Voucher </span>
      <v-progress-circular
        v-else
        indeterminate
        color="white"
      ></v-progress-circular>
    </button>

    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :preview-modal="false"
      :paginate-elements-by-height="1400"
      filename="Voucher"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="portrait"
      ref="html2Pdf"
    >
      <div slot="pdf-content" class="content">
        <header>
          <div
            class="d-flex justify-content-between align-items-center pt-5 pb-2 section"
          >
            <img src="@/assets/images/enseada-blue.png" alt="logo enseada" />
            <div class="text-right">
              <p>CNPJ: 08.067.903/0001-00</p>
              <p>Avenida Monsenhor Tabosa, 937</p>
              <p>Meireles, Fortaleza-CE, 60165-011</p>
              <p>Tel: (85) 3219-1999 | contato@enseada.tur.br</p>
            </div>
          </div>
          <hr />

          <div class="pb-2 mb-2 section">
            <h5>Solicitante:</h5>
            <p>{{ voucher.cliente }}</p>
            <h5>CPF:</h5>
            <p>{{ voucher.cpf }}</p>
            <h5>Data da compra:</h5>
            <p>{{ voucher.compra_data }}</p>
          </div>
        </header>

        <main>
          <div class="d-flex pb-2 my-2 descricao section">
            <div class="start">
              <h3>
                Descrição
              </h3>
              <div class="start">
                <h5>Pacote:</h5>
                <p>{{ voucher.pacote_nome }}</p>
                <h5>Data de saída:</h5>
                <p>{{ voucher.pacote_agenda }}</p>
                <h5>Local de saída:</h5>
                <p>{{ voucher.saida }}</p>
                <h5>Adultos:</h5>
                <p>{{ voucher.pacote_quantidade }}</p>
                <h5>Crianças 0-4 anos:</h5>
                <p>{{ voucher.pacote_quantidade_c1 }}</p>
                <h5>Criaças 5-12 anos:</h5>
                <p>{{ voucher.pacote_quantidade_c2 }}</p>
              </div>
            </div>

            <div class="start">
              <h3>
                Pagamento
              </h3>
              <div>
                <h5>{{ voucher.pacote_quantidade }} Adultos:</h5>
                <p>
                  R$
                  {{
                    parseFloat(voucher.total_adulto)
                      | money({
                        prefix: "",
                        suffix: "",
                        thousands: ".",
                        decimal: ",",
                        precision: 2,
                      })
                  }}
                </p>
                <h5>{{ voucher.pacote_quantidade_c1 }} Crianças 0-4 anos:</h5>
                <p>
                  R$
                  {{
                    parseFloat(voucher.total_c1)
                      | money({
                        prefix: "",
                        suffix: "",
                        thousands: ".",
                        decimal: ",",
                        precision: 2,
                      })
                  }}
                </p>
                <h5>{{ voucher.pacote_quantidade_c2 }} Crianças 5-12 anos:</h5>
                <p>
                  R$
                  {{
                    parseFloat(voucher.total_c2)
                      | money({
                        prefix: "",
                        suffix: "",
                        thousands: ".",
                        decimal: ",",
                        precision: 2,
                      })
                  }}
                </p>
                <h5>Valor Total:</h5>
                <p>
                  R$
                  {{
                    parseFloat(voucher.valorTotal)
                      | money({
                        prefix: "",
                        suffix: "",
                        thousands: ".",
                        decimal: ",",
                        precision: 2,
                      })
                  }}
                </p>
                <h5>Status da compra:</h5>
                <p>{{ voucher.compra_status && "PAGO" }}</p>
              </div>
            </div>
          </div>
        </main>

        <div class="pb-2 my-2 section">
          <h5>{{ voucher.pacote_nome }}</h5>
          <p>
            {{
              produtosPacote
                .reduce((acc, c) => acc + `- ${c.produto}`, "")
                .substr(2)
            }}
          </p>
          <p>
            Vale ressaltar que: Não nos responsabilizamos por qualquer outro
            passeio contratado diretamente com o guia durante a excursão,
            ficando claro que é de sua inteira responsabilidade a opção de
            compra, onde ao optar pelo passeio opcional, poderá ocorrer
            alteração na sequência do roteiro, podendo não dá tempo de realizar
            o roteiro por completo do pacote contratado.
          </p>
        </div>

        <!---<h4>PROTOCOLO DE SEGURANÇA</h4><br>
              <p>1. É expressamente proibido a entrada nos veículos sem o devido uso de máscaras, sendo obrigatório durante todo o
              percurso.</p>
              <p>2. Nossos colaboradores usarão máscaras durante toda a excursão, estando também com álcool em gel e álcool 70% á
              disposição dos nossos turistas.</p>
              <p>3. Em nosso Estado está sendo exigido o passaporte de vacina, tornando-se obrigatório em alguns pontos de apoio,
              restaurantes e estabelecimentos.</p>
              <p>4. Nossos veículos são higienizados sempre antes de iniciar cada viagem e o mesmo processo é realizado durante a
              excursão.</p>
              <p>5. É expressamente proibido o consumo de qualquer tipo de alimentação ou bebida no interior do veículo.</p>
              <p>6. É expressamente proibido adentrar nos veículos molhado, uma vez que o mesmo será utilizado para os passeios no dia seguinte.</p>
              <p >7. Em caso de descumprimento de qualquer um dos itens acima, o passageiro será convidado a deixar a excursão, não
              sendo possível, qualquer tipo de reembolso.</p>
              <p>8. Mediante qualquer tipo de situação, pedimos a gentileza para que não seja guardado nada dentro do veículo no
              momento que o mesmo ficar parado, solicitamos também a inteira atenção de todos na hora do desembarque nos pontos
              de apoio, bem como nos hotéis de destino, pois não nos responsabilizamos por qualquer objeto, guardado ou esquecido
              no interior dos veículos, uma vez que trabalhamos com veículos terceirizados e com serviço de fretamento.</p>
        

              <h4 class="mt-2">POLÍTICAS DE RESERVAS E CONDIÇÕES DE CANCELAMENTO</h4><br>

              <p>1- Solicitamos que o comprovante de pagamento seja anexado junto ao voucher para qualquer
              eventualidade.</p>
              <p>2- Em caso de desistência ou quebra de acordo o cliente não terá direito ao desconto concedido no pacote, passando a
              vigora os valores tabelados de cada passeio sem desconto.</p>
              <p>3- Em casos de desistência de Pacote com Hospedagem, Serviço Privativo ou Promoção não será reembolsado o valor já
              pago a empresa, tendo em vista que o mesmo é repassado no ato da reserva para bloqueio de pousadas, bem como
              serviços terceirizados e despesas administrativas.</p>
              <p>4- Alterações de datas de Pacotes com Hospedagem, Serviço Privativo ou Pacote Promocional, será mediante
              disponibilidade de vagas e podendo haver reajuste de valores.</p>
              <p>5- Em caso de cancelamentos de um ou mais passeios inclusos no pacote de passeios diários e pagamento feito em
              cartão de crédito/débito o reembolso será parcial, ficando retido 30% do valor do respectivo passeio para fins
              administrativos, além de ser descontado a taxa de 8,35% do cartão mais a taxa de parcelamento de 4,9% se for o caso e
              os demais passeios perderam os descontos concedidos e em caso de ser o último passeio será atualizado os valores dos
              passeios já realizados.</p>
              <p>6- Em caso de cancelamento 24hs antes do evento, a empresa fica no direito de cobrar 50% referente ao valor do
              passeio, para custear as despesas com a referida reserva.</p>
              <p class="html2pdf__page-break">7- Cancelamentos em casos de doenças comprobatórios a empresa se prontificará a oferecer um crédito para uma outra
              oportunidade, mediante disponibilidade de vagas com validade de 30 dias.</p>
              <p>8- Em casos de no-show não será possível reembolso.</p>
              <p>9- A agência pode transferir o serviço para outra empresa do mesmo seguimento, caso não haja formação de grupo.</p>
              <p>10- O cliente declara está ciente a todas as políticas e condições de cancelamento citadas anteriormente respeitando todas as normas da empresa.</p>--->
        <img src="./images/Enseada_Voucher_page-1.jpg" alt="" class="pagina" />

        <!---<div class="my-3 pb-2 text-danger">
          <p>POLÍTICAS E CONDIÇÕES DE RESERVAS E CANCELAMENTO PARA COMPRAS ATRAVÉS DO SITE</p><br>
          <p>1- EM CASO DE CANCELAMENTO DE PACOTES PROMOCIONAIS, NÃO SERÁ POSSÍVEL REEMBOLSO.</p>
          <p>2- EM CASO DE CANCELAMENTO 24HS ANTES DO EVENTO, A EMPRESA FICA NO DIREITO DE COBRAR 100%
          REFERENTE AO VALOR DO PASSEIO PARA CUSTEAR ÁS DESPESAS COM A REFERIDA RESERVA.</p>
          <p>3- CANCELAMENTOS EM CASOS DE DOENÇAS COMPROBATÓRIOS A EMPRESA SE PRONTIFICARÁ A OFERECER UM
          CRÉDITO PARA UMA OUTRA OPORTUNIDADE, MEDIANTE DISPONIBILIDADE DE VAGAS COM VALIDADE DE 180 DIAS, CASO
          CONTRÁRIO SERÁ RESTITUIDO 70% DO VALOR PAGO.</p>
          <p class="html2pdf__page-break">4- PARA CANCELAMENTOS DE PACOTES COM 30 DIAS ANTES DO EVENTO, SERÁ APLICADO MULTA DE 30% DO VALOR
          PAGO, PARA CUSTEAR AS DESPESAS DO SITE, SENDO O VALOR TRANSFERIDO APENAS PARA O TITULAR DA COMPRA,
          COM DOMICILIO BANCÁRIO NOS SEGUINTES BANCOS, CAIXA ECONÔMICA FEDERAL, ITAÚ, BRADESCO OU SANTANDER NO
          PRAZO DE 30 DIAS UTÉIS.</p>
          <p class="mt-3">5- PARA ALTERAÇÃO DE DATAS, APENAS SERÁ PERMITIDO COM NO MINIMO 07 DIAS DE ANTECEDÊNCIA DE ACORDO
          COM DISPONIBILIDADE, PODENDO HAVER REAJUSTE DE VALORES.</p>
          <p>6- EM CASO DE NO-SHOW NÃO SERÁ POSSÍVEL REMARCAR O PASSEIO, ASSIM COMO TAMBÉM NÃO SERÁ POSSÍVEL
          REEMBOLSO.</p>
          <p>7- A AGÊNCIA PODE TRANSFERIR O SERVIÇO PARA OUTRA EMPRESA DO MESMO SEGUIMENTO, CASO NÃO HAJA
          FORMAÇÃO DE GRUPO.</p>
          <p>8- O CLIENTE DECLARA ESTÁ CIENTE A TODAS AS POLITICAS E CONDIÇÕES DA EMPRESA.</p>
        </div>--->
        <img src="./images/Enseada_Voucher_page-2.jpg" alt="" class="pagina" />

        <img src="./images/Enseada_Voucher_page-3.jpg" alt="" class="pagina" />
      </div>
    </vue-html2pdf>
  </div>
</template>

<script>
import VoucherService from "@/services/voucher_service";
import VueHtml2pdf from "vue-html2pdf";

export default {
  props: ["pacoteId", "carrinhoId", "agenda", "produtosPacote"],
  components: {
    VueHtml2pdf,
  },
  data: () => ({
    voucher: {},
    conteudoPdf: "",
    loading: false,
  }),
  methods: {
    async emitirVoucher(pacoteId, carrinhoId, agenda) {
      this.loading = true;
      const voucherService = new VoucherService();
      await voucherService.getVoucher(
        {
          onSucess: (status) => (body) => {
            if (status === 200) {
              this.voucher = body.data.compras[0];
              this.voucher.valorTotal =
                Number(this.voucher.total_adulto) +
                Number(this.voucher.total_c1) +
                Number(this.voucher.total_c2);
              console.log(this.voucher);
              this.voucher.compra_data = this.voucher.compra_data
                .substr(0, 10)
                .split("-")
                .reverse()
                .join("/");
              this.voucher.pacote_agenda = this.voucher.pacote_agenda
                .substr(0, 10)
                .split("-")
                .reverse()
                .join("/");
              this.$refs.html2Pdf.generatePdf();
              this.loading = false;
            }

            if (status === 400 || status === 500) {
              this.$router.push({ name: "login" });
            }
          },
          onError: () => {},
          onEnd: () => {
            this.loading = false;
          },
        },
        `${pacoteId}/${carrinhoId}/${agenda}`
      );
    },

    formatData(data) {
      return data
        .substr(0, 10)
        .split("-")
        .reverse()
        .join("/");
    },

    /*emitirVoucher(pacoteId, carrinhoId, agenda){
      console.log(pacoteId)
      console.log(carrinhoId)
      console.log(agenda)
      console.log(this.voucher)
      //this.$refs.html2Pdf.generatePdf()
    }*/
  },
};
</script>

<style scoped>
.btn-primary {
  font-weight: bold;
  margin-top: 1rem;
  width: 100%;
  background-color: #2b3a8f;
  border: 1px solid #2b3a8f;
}

.btn-primary:hover {
  background-color: #fff;
  color: #2b3a8f;
}

.start {
  text-align: start;
}

.content {
  width: 700px;
  margin: auto;
}

.section {
  border-bottom: 1px solid #000;
}

.descricao {
  gap: 14rem;
}

p {
  color: #000;
}

.pagina {
  height: 1120px;
  transform: translateX(-50px);
}
</style>
