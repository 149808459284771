import { render, staticRenderFns } from "./carrinho_item.vue?vue&type=template&id=a036fe24&scoped=true&"
import script from "./carrinho_item.vue?vue&type=script&lang=js&"
export * from "./carrinho_item.vue?vue&type=script&lang=js&"
import style0 from "./carrinho_item.vue?vue&type=style&index=0&id=a036fe24&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a036fe24",
  null
  
)

export default component.exports