<template>
  <div class="home">
    <div>
      <header-geral />
    </div>

    <div class="container mtsc-4">
      <v-card class="pt-4">
        <h3>Agendamentos pendentes</h3>
        <v-card-text>
          <div class="row container p-4">
            <div class="col-12" v-if="agendamentos.length > 0">
              <v-card
                v-for="agendamento in agendamentos"
                :key="agendamento.agendamento_id"
                class="col-12 d-flex flex-column mb-4 rounded-t-lg p-2"
                elevation="0"
                outlined
              >
                <div
                  class="grey lighten-3 row rounded-t-lg"
                  style="padding: 0; text-align: left"
                >
                  <h4 class="col">
                    {{ agendamento.data | fmtDate }} -
                    {{ agendamento.nome_paciente }}
                  </h4>

                  <v-icon
                    elevation="0"
                    @click="agendamento.showVoucher = true"
                    small
                    class="mr-4"
                    v-if="!agendamento.showVoucher"
                    >mdi-eye</v-icon
                  >
                  <v-icon
                    elevation="0"
                    @click="agendamento.showVoucher = false"
                    small
                    class="mr-4"
                    v-else
                    >mdi-close</v-icon
                  >
                </div>

                <div
                  v-if="agendamento.showVoucher"
                  class="
                    voucher-container
                    row
                    p-10
                    flex-column
                    d-flex
                    align-content-right
                  "
                >
                  <div
                    class="voucher-card"
                    :id="'ag' + agendamento.agendamento_id"
                    :refs="'ag' + agendamento.agendamento_id"
                  >
                    <v-row>
                      <v-col cols="6"> </v-col>
                      <v-col cols="6">
                        <img src="@/assets/images/logo-saocarlos.png" />
                      </v-col>
                    </v-row>
                    <v-row class="tb">
                      <v-col cols="12">
                        <h3>Voucher {{ agendamento.produto_nome }}</h3>
                        <h4>São Carlos Vacinas</h4>
                        <h4>Serviço de Vacinação</h4>
                      </v-col>
                      <hr />
                      <v-col cols="12">
                        <table>
                          <tr>
                            <td><strong>nome</strong>:</td>
                            <td>{{ agendamento.nome_paciente }}</td>
                          </tr>

                          <tr>
                            <td><strong>cpf</strong>:</td>
                            <td>{{ agendamento.cpf_paciente }}</td>
                          </tr>

                          <tr>
                            <td><strong>data de nascimento</strong>:</td>
                            <td>
                              {{
                                agendamento.data_nascimento
                                  .split("-")
                                  .reverse()
                                  .join("/")
                              }}
                            </td>
                          </tr>

                          <tr>
                            <td><strong>vacina</strong>:</td>
                            <td>{{ agendamento.produto_nome }}</td>
                          </tr>

                          <tr>
                            <td><strong>data aplicação</strong>:</td>
                            <td>{{ agendamento.data | fmtDate }}</td>
                          </tr>

                          <tr>
                            <td><strong>localidade aplicação</strong>:</td>
                            <td>{{ agendamento.unidade_nome }}</td>
                          </tr>
                        </table>

                        <hr />
                        <strong>Serviços Inclusos:</strong> <br />
                        - Este Voucher, contempla disponibilização e aplicação
                        de dose individual de
                        {{ agendamento.produto_nome }}, em local e data pré
                        acordado, conforme as informações acima
                        <hr />
                        <strong>Observações:</strong> <br />
                        - O paciente é o único responsável pelas informações dos
                        dados fornecidos para aplicação de
                        {{ agendamento.produto_nome }} <br />
                        - A São Carlos Vacinas irá realizar contato com o
                        paciente em até 05 (cinco) dias úteis antes da data
                        acordada para aplicação da Vacina, para fins de
                        confirmação da agenda. <br />
                        - Havendo possibilidade de remarcação da data prevista
                        de aplicação, em caso de justo motivo ou força maior,
                        deve ser informado com antecedência de até 48 hrs de
                        qualquer uma das partes.

                        <hr />

                        O produto atende aos requisitos da Organização Mundial
                        da Saúde (OMS) sobre substâncias biológicas evacinas
                        contra gripe.

                        <hr />

                        Fortaleza, {{ new Date().getDay() }} de
                        {{ new Date().getMonth() + 1 }} de
                        {{ new Date().getFullYear() }}.

                        <v-row class="mt-2">
                          <v-col style="margin: 0" cols="5">
                            <hr class="assina" />
                          </v-col>
                          <v-col cols="2"> </v-col>
                          <v-col cols="5">
                            <hr class="assina" />
                          </v-col>
                        </v-row>
                        <v-row style="margin: 0">
                          <v-col style="margin: 0" cols="5">
                            <strong>Paciente</strong>
                          </v-col>
                          <v-col cols="2"> </v-col>
                          <v-col style="margin: 0" cols="5">
                            <strong>São Carlos Vacinas</strong>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </div>
                  <v-row class="mt-6">
                    <v-btn
                      @click="
                        createCartaoDeVacinaPdf(
                          'ag' + agendamento.agendamento_id
                        )
                      "
                      class="appBG-primary comprar-button white--text"
                    >
                      Gerar voucher</v-btn
                    >
                  </v-row>
                </div>
              </v-card>
            </div>
            <h4 class="grey--text" v-else>Não há gesto vacinal cadastrado</h4>
          </div>
        </v-card-text>
      </v-card>
    </div>
    <div class="mt-16">
      <footer-app />
    </div>

    <img
      v-show="false"
      ref="imagePDF"
      src="@/assets/images/logo-saocarlos.png"
    />
  </div>
</template>

<script>
import AgendamentoService from "../services/agendamento_service";
import headerGeral from "@/components/template/header/header_geral.vue";
import footerApp from "@/components/template/footer_app.vue";
import jsPDF from "jspdf";

export default {
  components: {
    headerGeral,
    footerApp,
  },

  data: () => ({
    pessoas: [],
    agendamentos: [],
    tickvacinas: 0,
  }),

  filters: {
    fmtDate(date) {
      return date.split(" ")[0].split("-").reverse().join("/");
    },
  },

  beforeMount() {
    if (!this.$store.state.token) {
      this.$router.push("/");
    }

    this.atualizaDados();
  },

  mounted() {
    this.atualizaDados();
  },

  methods: {
    createCartaoDeVacinaPdf(refarq) {
      const hash = new Date().getUTCMilliseconds();
      let html = document.querySelector(`#${refarq}`);

      const doc = new jsPDF();

      doc.html(html, {
        callback: function (doc) {
          doc.save(`VOUCHER_SAO_CARLOS_${hash}.pdf`);
        },
        x: 0,
        y: 0,
        width: 222,
        windowWidth: 922,
      });
    },

    closeDialog() {
      this.dialog = false;
      this.atualizaDados();
    },

    atualizaDados() {
      this.atualizaListaAgendamentos();
    },

    atualizaListaAgendamentos() {
      const agendamentoService = new AgendamentoService();

      const onSucess = (status) => (body) => {
        if (status === 200) {
          this.agendamentos = body.data.agendamentos.map((a) => {
            a.showVoucher = false;

            return a;
          });
          this.tickvacinas++;
        }
      };

      const onError = (error) => {
        console.log(error);
      };

      const onEnd = () => {
        return; //
      };

      agendamentoService.getListPendentes({ onSucess, onError, onEnd });
    },
  },
};
</script>

<style scoped>
.banner-principal {
  margin-top: 8rem;
}

.mtsc-4 {
  margin-top: 10rem;
}

.voucher-card {
  width: 75%;
  margin: 0 auto;
  text-align: left;
}

.voucher-card table {
  width: 100%;
}

.voucher-card tr {
  width: 100%;
}

.voucher-container {
  padding: 10px;
  margin: 20px;
}

.voucher-card {
  padding: 25px;
  background: #f4f4f4;
}

.voucher-card h1,
.voucher-card h2,
.voucher-card h3,
.voucher-card h4 {
  margin-left: 40px;
  line-height: 1;
}

.voucher-card h4 {
  font-weight: 500;
}

.voucher-card * {
  color: black !important;
}

.voucher-card hr {
  border-top: 1px solid black;
}

.voucher-card hr.assina {
  border-top: 2px solid black;
  margin-bottom: -10px;
}
</style>
