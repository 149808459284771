import Home from '@/views/Home.vue'
import QuemSomos from '@/views/quem_somos.vue'
import DetalhesProduto from '@/views/detalhes_produto.vue'
import Login from '@/views/login.vue'
import NovaConta from '@/views/nova_conta.vue'
import EsqueceuSenha from '@/views/esqueceu_senha.vue'
import AlterarDados from '@/views/alterar-dados.vue'
import MeusPedidos from '@/views/meus_pedidos.vue'
import Agendamento from '@/views/Agendamento.vue'
import Pedido from '@/views/Pedido.vue'
import Checkout from '@/views/Checkout.vue'
import CartaoVacina from '@/views/cartao_vacina.vue'
import Agendamentos from '@/views/agendamentos.vue'
import DetalhePacote from '@/views/detalhe_pacote.vue'
import DetalhesCategoria from '@/views/detalhes_categoria'
import PromoDetalhesCategoria from '@/views/promo_detalhes_categoria'


const routes = [
{
    path: '/',
    name: 'home',
    component: Home,
},
{
    path: '/detalhes-categoria',
    name: 'detalhes-categoria',
    component: DetalhesCategoria,
},
{
    path: '/promo-detalhes-categoria',
    name: 'promo-detalhes-categoria',
    component: PromoDetalhesCategoria,
},
{
    path: '/detalhe-pacote',
    name: 'detalhe-pacote',
    component: DetalhePacote,
},
{
    path: '/quem-somos',
    name: 'quem-somos',
    component: QuemSomos,
},
{
    path: '/detalhes-produto/:produtoId',
    name: 'detalhes-produto',
    component: DetalhesProduto,
},
{
    path: '/login',
    name: 'login',
    component: Login,
},
{
    path: '/nova-conta',
    name: 'nova-conta',
    component: NovaConta,
},
{
    path: '/esqueceu-senha',
    name: 'esqueceu-senha',
    component: EsqueceuSenha,
},
{
    path: '/alterar-dados',
    name: 'alterar-dados',
    component: AlterarDados,
},
{
    path: '/meus-pedidos',
    name: 'meus-pedidos',
    component: MeusPedidos,
},
{
    path: '/agendamento',
    name: 'agendar-vacina',
    component: Agendamento,
},
{
    path: '/pedido/:id',
    name: 'pedido',
    component: Pedido,
},
{
    path: '/checkout',
    name: 'checkout',
    component: Checkout,
},
{
    path: '/cartao-vacina',
    name: 'cartao_vacina',
    component: CartaoVacina,
},
{
    path: '/agendamentos',
    name: 'agendamentos',
    component: Agendamentos,
},

    // {
    //     path: '/about',
    //     name: 'About',
    //     // route level code-splitting
    //     // this generates a separate chunk (about.[hash].js) for this route
    //     // which is lazy-loaded when the route is visited.
    //     component: () =>
    //         import ( /* webpackChunkName: "about" */ '../views/About.vue')
    // }
]

export default routes
