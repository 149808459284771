import HTTPService from '@/services/http_service'

export default class ViagensAgendadas extends HTTPService{
  resource = 'compras/agenda'

  getAll({onSucess, onError, onEnd}){
    const responseFunctions = {
      onSucess,
      onError,
      onEnd
    }

    this.request(responseFunctions, 'GET', this.resource, null)
  }
}