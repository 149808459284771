<template>
  <div>
    <div id="pacotes" class="tpac container">
      <p class="appColor-blue titles">Passeios de 1 dia</p>
      <p class="appColor-blue font sub">
        Passeios inesquecíveis para curtir no Ceará
      </p>
      <div class="bloco mb-5">
        <div class="col-12">
          <carousel
            class="slidezinx"
            navigationEnabled
            :perPageCustom="[
              [300, 1],
              [400, 2],
              [800, 3],
              [1000, 4],
            ]"
            :touchDrag="true"
            :loop="true"
          >
            <slide
              class="passeiox"
              v-for="(produto, i) in categoriaBateVolta"
              :key="i"
            >
              {{ getProduto(produto.pacote_id, i) }}
              <v-img
                :src="produto.src"
                class="text-center image"
                max-height="220"
                aspect-ratio="2"
                cover
              >
                <template v-slot:placeholder>
                  <v-sheet
                    color="grey lighten-4"
                    class="px-3 pt-3 pb-3 fill-height"
                  >
                    <v-skeleton-loader
                      class="mx-auto"
                      type="image"
                    ></v-skeleton-loader>
                  </v-sheet>
                </template>
              </v-img>
              <!-- <v-sheet :color="`grey lighten-4`">
                <v-skeleton-loader
                  class="mx-auto"
                  max-width="394"
                  height="220"
                  type="image"
                ></v-skeleton-loader>
              </v-sheet> -->
              <!-- <h1>Categoria: {{produto.categorias}}</h1> -->
              <div class="p-2">
                <p class="appColor-blue font">{{ produto.titulo }}</p>
                <!-- <p class="font d-inline-block text-truncate">
                    {{ produto.descricao }}
                  </p> -->
                <p class="valor font">
                  R$
                  {{
                    produto.valorAtual
                      | money({
                        prefix: "",
                        suffix: "",
                        thousands: ".",
                        decimal: ",",
                        precision: 2,
                      })
                  }}
                </p>
                <v-btn
                  class="appBG-primary appColor-white mt-2 btnslide"
                  :href="produto.href"
                  @click="detalhesProduto(produto.produtoId)"
                >
                  Saiba mais
                </v-btn>
              </div>
            </slide>
          </carousel>
        </div>
      </div>
    </div>
    <div class="tpac2 container mb-5">
      <p class="appColor-blue titles">Passeios com hospedagem</p>
      <p class="appColor-blue font sub">
        Aproveite essas ofertas de passeio com hospedagem
      </p>
      <div class="bloco">
        <div class="col-6">
          <carousel
            class="slidezin tpac"
            navigationEnabled
            :navigationClickTargetSize="30"
          >
            <slide
              class="slide"
              v-for="(produto, i) in categoriaTudoIncluso"
              :key="i"
            >
              <v-img
                :src="produto.src"
                class="text-center image"
                max-height="220"
                max-width="246"
                aspect-ratio="2"
                cover
              >
                <template v-slot:placeholder>
                  <v-sheet
                    color="grey lighten-4"
                    class="px-3 pt-3 pb-3 fill-height"
                  >
                    <v-skeleton-loader
                      class="mx-auto"
                      type="image"
                    ></v-skeleton-loader>
                  </v-sheet>
                </template>
              </v-img>
              <div class="p-2">
                <p class="appColor-blue font">{{ produto.titulo }}</p>
                <!-- <p class="font d-inline-block text-truncate">
                  {{ produto.descricao }}
                </p> -->
                <!-- <p class="valor font">
                  R$
                  {{
                    /*produto.valorAtual
                      | money({
                        prefix: "",
                        suffix: "",
                        thousands: ".",
                        decimal: ",",
                        precision: 2,
                      })*/
                      produto.valorAtual
                  }}
                </p> -->
                <p class="valor font">
                  R$
                  {{
                    produto.valorAtual
                      | money({
                        prefix: "",
                        suffix: "",
                        thousands: ".",
                        decimal: ",",
                        precision: 2,
                      })
                  }}
                </p>
                <v-btn
                  class="appBG-primary appColor-white mt-2 btnslide"
                  :href="produto.href"
                  @click="detalhesProduto(produto.produtoId)"
                >
                  Saiba mais
                </v-btn>
              </div>
            </slide>
          </carousel>
        </div>
        <div class="col-6">
          <img class="imgipad" src="@/assets/images/foto2.png" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Carousel, Slide } from "vue-carousel";
import LojaService from "@/services/loja_service";

export default {
  mounted() {
    this.getList();
  },

  data: () => {
    return {
      produtos: [],
      categoriaBateVolta: [],
      categoriaTudoIncluso: [],
      categoriaTransferComPasseio: [],
      dates: [
        new Date().toISOString().substr(0, 10),
        new Date().toISOString().substr(0, 10),
      ],
      abrir: false,
    };
  },

  components: {
    Carousel,
    Slide,
  },

  methods: {
    getList() {
      this.loading = true;
      const lojaService = new LojaService();
      this.produtos = [];
      const onSucess = (status) => (body) => {
        if (status === 200) {
          console.log(body);
          this.produtos = body.data.loja.map((pack) => ({
            pacote_id: pack.pacote_id,
            teste: "",

            src: pack.foto ?? require("@/assets/images/produto.png"),

            titulo: pack.nome,
            // junta o titulo dos produtos
            descricao: pack.produtos.reduce(
              (acc, p) => acc + ` - ${p.produto}`,
              ""
            ),
            // valorAnterior: "R$ 350",
            // valorAtual: pack.preco,
            valorAtual: String(parseFloat(pack.preco).toFixed(2)).replace(
              ".",
              ","
            ),

            // preco_parcelado: "",
            href: `/detalhes-produto/${pack.pacote_id}`,
            categorias: pack.produtos.map((produto) => produto.categorias),
          }));

          for (let i = 0; i < this.produtos.length; ++i) {
            for (let j = 0; j < this.produtos[i].categorias.length; ++j) {
              if (
                this.produtos[i].categorias[j].filter(
                  (categ) => categ.nome == "Passeio"
                ).length > 0
              ) {
                this.categoriaBateVolta.push(this.produtos[i]);
              }
            }
          }

          for (let i = 0; i < this.produtos.length; ++i) {
            for (let j = 0; j < this.produtos[i].categorias.length; ++j) {
              if (
                this.produtos[i].categorias[j].filter(
                  (categ) => categ.nome == "Hospedagem com café da manhã"
                ).length > 0
              ) {
                this.categoriaTudoIncluso.push(this.produtos[i]);
              }
            }
          }

          // for (let i = 0; i < this.produtos.length; ++i) {
          //   if (
          //     this.produtos[i].categorias.filter(
          //       (categ) => (categ.nome = "qualquer coisa")
          //     ).length > 0
          //   ) {
          //     this.categoriaTudoIncluso.push(this.produtos[i]);
          //   }
          //   console.log(this.categoriaTudoIncluso);
          //   // for (let j = 0; j < this.produtos[i].categorias.length; ++j) {
          //   //   if (this.produtos[i].categorias[j] == "Tudo incluso") {
          //   //     this.categoriaTudoIncluso.push(this.produtos[i]);
          //   //     break;
          //   //   }
          //   // }
          // }

          // for (let i = 0; i < this.produtos.length; ++i) {
          //   for (let j = 0; j < this.produtos[i].categorias.length; ++j) {
          //     console.log("entrou");
          //     if (this.produtos[i].categorias[j] == "Transfer com passeio") {
          //       this.categoriaTransferComPasseio.push(this.produtos[i]);
          //       break;
          //     }
          //   }
          // }

          // console.log('categorias: ', this.categoriaBateVolta)
        } else {
          // console.log(body);
        }
      };
      const onError = (error) => {
        console.log(error);
      };
      const onEnd = () => {
        this.loading = false;
      };
      lojaService.getList({ onSucess, onError, onEnd });
    },
    getProduto(id, index) {
      this.loading = true;
      const lojaService = new LojaService();
      const onSucess = (status) => (body) => {
        if (status === 200) {
          // this.totalList = body.data.pagination.num_rows;
          const produto = body.data.loja[0];
          if (this.categoriaBateVolta[index].teste == "") {
            console.log(this.categoriaBateVolta);
            this.categoriaBateVolta[index].src = produto.foto;
          }
        }
      };
      const onError = (error) => {
        console.log(error);
      };
      const onEnd = () => {
        this.loading = false;
      };

      lojaService.getOne(
        {
          onSucess,
          onError,
          onEnd,
        },
        id
      );
    },
    detalhesProduto(produtoId) {
      this.$router.replace({
        name: "detalhes-produto",
        params: { produtoId: produtoId },
      });
    },
  },
};
</script>
<style scoped>
.slide .appColor-blue {
  font-weight: 700;
  font-size: 17px;
}
.btnslide,
.btnslide:focus {
  border: 0px;
  width: 100%;
  border-radius: 16px;
}
.valor {
  font-weight: 700;
}
.slidezin .slide {
  box-shadow: 0px -4px 6px #00000029;
  border-radius: 16px;
  flex-basis: auto;
  margin: 55px 0px 15px 15px;
  width: 170px;
}
.slidezin .passeio {
  box-shadow: 0px -4px 6px #00000029;
  border-radius: 16px;
  flex-basis: auto;
  margin: 55px 0px 15px 15px;
  width: 268px;
}
.passeio .image {
  width: 268px !important;
  margin: auto !important;
}
.sub {
  font-size: 21px;
}
.tpac {
  text-align: left;
}
.tpac2 {
  text-align: right;
}
.image {
  padding-bottom: 34%;
  border-radius: 16px 16px 0px 0px;
}
.text-truncate {
  max-width: 149px;
}
.VueCarousel-slide {
  padding: 0 20px !important;
}
@media (max-width: 600px) {
  .tpac2 img,
  .tpac img {
    width: 100%;
  }
  .tpac,
  .tpac2 {
    text-align: center;
  }
  .slidezin .passeio {
    margin: 54px 40px 0px 40px;
  }
}
@media (max-width: 426px) {
  .slidezin .passeio {
    margin: 54px 15vw 0px 11vw;
  }
}

@media (max-width: 376px) {
  .slidezin .passeio {
    margin: 54px 10vw 0px 6vw;
  }
}
</style>
