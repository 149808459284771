<template>
    <div>
      <div style="z-index:99999 !important; background: rgb(250,143,1);
      background: linear-gradient(0deg, rgba(250,143,1,1) 24%, rgba(254,198,0,1) 100%);!important; padding:10px;">
       <div class="sub-case-menu">
        <Menu_loja />
       </div>

      </div>

      <flickity ref="flickity" :options="flickityOptions">

            <!-- <div class="col-9">
              <div class="titles text-right txt-banner">
                <span
                  ><div class="appColor-primary">PACOTES COMPLETOS</div>
                  POR UM PREÇO QUE CABE NO SEU BOLSO!</span
                >
              </div>
            </div> -->
            <!-- <div class="row menu-home">
                  <div class="it">
                    <v-icon>mdi-magnify</v-icon>
                    <input class="form-control" v-model="destino" placeholder="Para onde você vai?">
                  </div>
                  <div class="it">
                    <v-icon>mdi-map-marker-outline</v-icon>
                    <input class="form-control" v-model="destino" placeholder="O que está buscando?">
                  </div>
                  <div class="it">
                    <v-icon>mdi-calendar-blank-outline</v-icon>
                    <input class="form-control" v-model="destino" placeholder="Ida?">
                  </div>
                  <div class="it">
                    <v-icon>mdi-calendar-blank-outline</v-icon>
                    <input class="form-control" v-model="destino" placeholder="Volta?">
                  </div>
                </div> -->
                <a class="carousel-cell"
                target="_blank"
                rel="noopener noreferrer"
                href="https://enseada.tur.br/detalhes-produto/83"
              >
                <img
                  v-if="!mobile"
                  src="@/assets/images/slideEnseada1.jpg"
                  class="bannerImg"
                  alt="..."
                />

                <img
                  v-else
                  src="@/assets/images/slideEnseada1.jpg"
                  class="bannerImg"
                  alt="..."
                />
            </a>   
   

          <a class="carousel-cell"
                target="_blank"
                rel="noopener noreferrer"
                href="https://enseada.tur.br/detalhes-produto/83"
              >
                <img
                  v-if="!mobile"
                  src="@/assets/images/full1.png"
                  class="bannerImg"
                  alt="..."
                />

                <img
                  v-else
                  src="@/assets/images/slideEnseada1.jpg"
                  class="bannerImg"
                  alt="..."
                />
            </a>


              <a class="carousel-cell"
                target="_blank"
                rel="noopener noreferrer"
                href="https://enseada.tur.br/detalhes-produto/80"
              >
                <img
                  v-if="!mobile"
                  src="@/assets/images/full2.png"
                  class="d-block bannerImg"
                  alt="..."
                />
                <img
                  v-else
                  src="@/assets/images/slide-mobile-enseada-08-11-2022-2.png"
                  class="bannerImg"
                  alt="..."
                />
              </a>

              

              <a
                class="carousel-cell"
                target="_blank"
                rel="noopener noreferrer"
                href="https://enseada.tur.br/detalhes-produto/80"
              >
                <img
                  v-if="!mobile"
                  src="@/assets/images/full3.png"
                  class="d-block bannerImg"
                  alt="..."
                />

                <img
                  v-else
                  src="@/assets/images/slide-mobile-enseada-08-11-2022-3.png"
                  class="bannerImg"
                  alt="..."
                />
              </a>

              <a
                class="carousel-cell"
                target="_blank"
                rel="noopener noreferrer"
                href="https://enseada.tur.br/detalhes-produto/"
              >
                <img
                  v-if="!mobile"
                  src="@/assets/images/full4.png"
                  class="d-block bannerImg"
                  alt="..."
                />

                <img
                  v-else
                  src="@/assets/images/slide-mobile-enseada-08-11-2022-4.png"
                  class="bannerImg"
                  alt="..."
                />
              </a>

      </flickity>

          <!-- <div v-else>
            <div  class="carousel-item carousel-container active">
              <a target="_blank" rel="noopener noreferrer" href="https://enseada.tur.br/detalhes-produto/83">
                <img 
                  src="@/assets/images/JeriMob.png" 
                  class="w-100" 
                  alt="..." 
                />
              </a>
            </div>

            <div class="carousel-item">
              <a target="_blank" rel="noopener noreferrer" href="https://enseada.tur.br/detalhes-produto/80">  
                <img
                  src="@/assets/images/MorroMob.png"
                  class="w-100"
                  alt="..."
                />
              </a>
            </div>

            <div class="carousel-item">
              <a target="_blank" rel="noopener noreferrer" href="https://enseada.tur.br/detalhes-produto/80">  
                <img
                  src="@/assets/images/LagoinhaMob.png"
                  class="w-100"
                  alt="..."
                />
              </a>
            </div>
          </div> -->

          <!-- <div class="carousel-item">
            <a target="_blank" rel="noopener noreferrer" href="https://enseada.tur.br/detalhes-produto/77">  
              <img
                src="@/assets/images/Lagoinha.png"
                class="d-block w-100"
                alt="..."
              />
            </a>
          </div> -->
        <div
          id="promo"
          class="bannercall content"
          :style="'background-image: url(' + bgTurismo + ');'"
        >
          <p class="txt appColor-blue">
            Monte seu pacote conosco
            <br />preços imperdíveis!
          </p>
          <a
            class="btn btn-primary appColor-blue bgtrans font btncall"
            target="_blank"
            href="https://api.whatsapp.com/send?phone=5585996081221&text=Ol%C3%A1%2C%20vi%20o%20site%20e%20quero%20informa%C3%A7%C3%B5es%20sobre%20reserva%20de%20hotel"
            >Eu quero</a
          >
        </div>
      </div>
</template>
<script>
import bgTur from "@/assets/images/banner2.png";
import Menu_loja from "./header/navbar/menu_loja.vue";
import Flickity from 'vue-flickity';
export default {
  components: {
    Menu_loja,
    Flickity,
  },

  data() {
    return {
      bgTurismo: bgTur,
      mobile: window.innerWidth <= 700,

      flickityOptions: {
        prevNextButtons: true,
        pageDots: false,
        wrapAround: true,
        autoPlay: 3500,
        
        // any options from Flickity can be used
      }
    }
  },

  created() {
    addEventListener("resize", () => {
      this.mobile = innerWidth <= 700;
    });
  },

  methods: {
    // isMobile() {
    //   if (
    //     /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    //       navigator.userAgent
    //     )
    //   ) {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // },
  },
    next() {
      this.$refs.flickity.next();
    },
    
    previous() {
      this.$refs.flickity.previous();
    }
};
</script>
<style scoped>
.carousel-cell {
  width: 100%; /* full width */
  background-size: cover;
}
.sub-case-menu{
  width:80%; 
  margin:auto;
}
.flickity-button {
  background: transparent;
}
/* big previous & next buttons */
.flickity-prev-next-button {
  width: 100px;
  height: 100px;
}
/* icon color */
.flickity-button-icon {
  fill: white;
}
.total {
  width: 100%;
}
.bannerImg {
  min-width: 100vw !important;
  height: 70vh;
}
.bannercall .txt {
  font-family: "Permanent Marker";
  font-size: 38px;
  line-height: 49px;
}
.bannercall {
  text-align: right;
  background-size: cover;
  padding: 3rem 11rem 2rem 0rem;
}
.btncall {
  border: 2px solid #2b3a8f;
  font-weight: 500;
  font-size: 19px;
  padding: 1rem 5rem 1rem 5rem;
  margin: 2rem 0rem 4rem 0rem;
}
.menu-home .it {
  display: flex;
}
.menu-home {
  display: flex;
  justify-content: space-between;
  list-style-type: none;
  margin-top: 1rem;
}

.carousel-container {
  display: flex;
}

.carousel-caption {
  /* top: 1rem; */
  top: 0;
  bottom: auto;
}
.carousel-inner {
  background-color: #fac301;
}
.txt-banner {
  margin-top: 2rem;
}
.menu-home input {
  width: 100% !important;
}
.menu-home .v-icon {
  margin-top: 0rem;
  background-color: #e8e8e8;
  height: 2.3rem;
  width: 21%;
  margin-right: -0.5rem;
  border-radius: 6px 0px 0px 6px;
  color: #2b3a8f;
}
input {
  background-color: #e8e8e8 !important;
  height: 2.3rem;
}
::-webkit-input-placeholder {
  color: #2b3a8f;
  font-weight: 600;
}
@media (max-width: 600px) {
  .bannerImg {
    min-width: 100vw !important;
    height: 80vh;
  }
  .btncall,
  .btncall:hover {
    border: 2px solid #2b3a8f;
    background-color: #2b3a8f;
    color: #fff !important;
  }
  .carousel-inner {
    height: 35rem;
  }
  .carousel-item {
    width: 100%;
  }

  .carousel-caption {
    justify-content: center;
  }
  .bannercall {
    text-align: center;
    background-size: cover;
    padding: 3rem 3rem 3rem 3rem;
    background-position-x: -23rem;
  }
  .txt-banner {
    margin-top: 2rem;
    line-height: 21px;
  }
  .menu-home {
    margin-top: 0rem;
    justify-content: center;
  }
  .menu-home .it {
    margin: 1% 0% 1% 0%;
  }
  .sub-case-menu{
    width:100%; 
    margin:auto;
  }
}
</style>
