import HTTPService from '@/services/http_service.js'

export default class LojaService extends HTTPService {
  resource = 'loja'
  cadastrar(responseFunctions, loja) {
    this.jsonRequest(responseFunctions, 'POST', this.resource, loja)
  }

  getList(responseFunctions) {
    this.request(responseFunctions, 'GET', this.resource, null)
  }
  
  getPacotePromocao(onSucess, onError, onEnd, page, per_page,) {
    const responseFunctions = {
      onSucess, 
      onError, 
      onEnd,
    }
    // this.request(responseFunctions, 'GET', `${this.resource}/promocao`);

    let queryparams = {
      page: page,
      per_page: per_page,
    }
    
    this.request(responseFunctions, 'GET', `${this.resource}/promocao`, null, queryparams);

    // if (filter === '') {
    //   if (order) {
    //     let queryparams = {
    //       page: page,
    //       per_page: per_page,
    //       sort: desc ? 'categoria' : '-categoria'
    //     }
    //     this.request(responseFunctions, 'GET', this.resource, null, queryparams)
    //   } else {
    //     this.request(responseFunctions, 'GET', this.resource, null, queryparams)
    //   }
    // } else {
    //   let queryparams = {
    //     page: page,
    //     per_page: per_page,
    //     categoria: filter,
    //   }
    //   if (order) {
    //     let queryparams = {
    //       page: page,
    //       per_page: per_page,
    //       categoria: filter,
    //       sort: desc ? 'categoria' : '-categoria'
    //     }
    //     this.request(responseFunctions, 'GET', `${this.resource}/promocao`, null, queryparams)
    //   } else {
    //     this.request(responseFunctions, 'GET', `${this.resource}/promocao`, null, queryparams)
    //   }
    // }
  }
  
  getListMostWanted(responseFunctions) {
    this.request(responseFunctions, 'GET', `${this.resource}/mais-procurados`, null)
  }

  getOne(responseFunctions, id) {
    this.request(responseFunctions, 'GET', `${this.resource}/${id}`, null)
  }

  getAgenda(onSucess, onError, onEnd, pacote_id, unidade_id, mes, ano) {
    this.request(
      { onSucess, onError, onEnd },
      "GET",
      `${this.resource}/agenda/${pacote_id}/${unidade_id}/${mes}/${ano}`,
      null
    );
  }

  getAll(onSucess, onError, onEnd, page, per_page, filter, order, desc) {
    const responseFunctions = {
      onSucess,
      onError,
      onEnd
    }

    let queryparams = {
      page: page,
      per_page: per_page,
    }


    if (filter === '') {
      if (order) {
        let queryparams = {
          page: page,
          per_page: per_page,
          sort: desc ? 'categoria' : '-categoria'
        }
        this.request(responseFunctions, 'GET', this.resource, null, queryparams)
      } else {
        this.request(responseFunctions, 'GET', this.resource, null, queryparams)
      }
    } else {
      let queryparams = {
        page: page,
        per_page: per_page,
        categoria: filter,
      }
      if (order) {
        let queryparams = {
          page: page,
          per_page: per_page,
          categoria: filter,
          sort: desc ? 'categoria' : '-categoria'
        }
        this.request(responseFunctions, 'GET', this.resource, null, queryparams)
      } else {
        this.request(responseFunctions, 'GET', this.resource, null, queryparams)
      }
    }
  }

}