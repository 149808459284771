import HTTPService from '@/services/http_service.js'

export default class VoucherService extends HTTPService {
  resource = 'compras/voucher'

  getVoucher({onSucess, onError, onEnd}, pedidoId){
    const responseFunctions = {
      onSucess, onError, onEnd
    }

    this.request(responseFunctions, 'GET', `${this.resource}/${pedidoId}`, null)
  }

}