<template>
  <div>
    <v-container>
      <div class="d-flex mb-12">
        <h2 class="text-left mr-2">Veja também</h2>
        <div class="pl-3" style="border-left: 3px solid #2b3a8f">
          <p class="appColor-secondary font-weight-bold text-left">
            Clientes que compraram este<br />
            item também compraram.
          </p>
        </div>
      </div>
      <div class="galeria-mais-procurado">
        <div
          class="row prod-rec mb-4 p-3 somb col-8 m-auto"
          v-for="(produto, i) in produtos"
          :key="i"
        >
          <div class="col-4 text-left">
            <h3 class="appColor-blue">{{ produto.titulo }}</h3>
            <!-- <h4 class="appColor-secondary mb-2">
                {{ produto.descricao }}
              </h4> -->
          </div>
          <div class="col-8 cardb">
            <div class="d-flex">
              <div class="col-8 d-flex">
                <h4 class="appColor-blue titles">
                  R$
                  {{
                    parseFloat(produto.valorAtual)
                      | money({
                        prefix: "",
                        suffix: "",
                        thousands: ".",
                        decimal: ",",
                        precision: 2,
                      })
                  }}
                </h4>
                <p class="font-weight-bold mb-0" v-if="produto.parcelado">
                  ou {{ produto.quantidade_parcelas }} x de R${{
                    produto.preco_parcelado
                  }}
                </p>
                <span class="diaria taxa">{{ produto.teste }} /diária</span>
              </div>
              <div class="col-4">
                <v-btn
                  class="app-Border-primary appColor-blue mt-2"
                  :href="produto.href"
                  @click="detalhesProduto(produto.produtoId)"
                >
                  Ver mais
                </v-btn>
              </div>
            </div>
            <!-- <span class="taxa">
                          Taxas e impostos inclusos | <b>até 12x</b>
                      </span> -->
          </div>
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
import LojaService from "@/services/loja_service";

export default {
  mounted() {
    this.getProdutos();
  },
  data: () => ({
    produtos: [],
  }),
  methods: {
    getProdutos() {
      this.loading = true;
      const lojaService = new LojaService();
      this.produtos = [];
      const onSucess = (status) => (body) => {
        if (status === 200) {
          console.log(body.data);

          this.produtos = body.data.loja.map((pack) => ({
            src: require("@/assets/images/minha-vacina.png"),
            titulo: pack.nome,
            // junta o titulo dos produtos
            descricao: pack.produtos.reduce(
              (acc, p) => acc + ` - ${p.produto}`,
              ""
            ),
            promocao: true,
            // valorAnterior: "R$ 350",

            valorAtual: pack.preco,
            parcelado: false,
            quantidade_parcelas: "",
            // preco_parcelado: "",
            href: `/detalhes-produto/${pack.pacote_id}`,
          }));
          this.produtos = this.produtos.slice(0, 4);
          //this.produtos.map( (p, i) => this.getProdutos(p, i))
          console.log(this.produtos);
        } else {
          // console.log(body);
        }
      };
      const onError = (error) => {
        console.log(error);
      };
      const onEnd = () => {
        this.loading = false;
      };
      lojaService.getListMostWanted({ onSucess, onError, onEnd });
    },
    detalhesProduto(produtoId) {
      this.$router.replace({
        name: "detalhes-produto",
        params: { produtoId: produtoId },
      });
    },
  },
  getProduto(produto, i) {
    this.loading = true;
    const lojaService = new LojaService();

    const onSucess = (status) => (body) => {
      if (status === 200) {
        // this.totalList = body.data.pagination.num_rows;
        const produtoReq = body.data.loja[0];
        console.log(body.data);
        produto = {
          prevencao: produtoReq.produtos
            .map((p) => p.prevencao)
            .filter((p) => p != null && p != ""),
          indicacoes: produtoReq.produtos
            .map((p) => p.indicacoes)
            .filter((p) => p != null),
          recomendacoes: produtoReq.produtos
            .map((p) => p.recomendacoes)
            .filter((p) => p != null),
          contraindicacoes: produtoReq.produtos
            .map((p) => p.contra_indicacoes)
            .filter((p) => p != null),
          esquemaDoses: produtoReq.produtos
            .map((p) => [p.nome, p.esquema_doses])
            .filter(
              ([nome, esquema_doses]) => nome != null && esquema_doses != null
            )
            .map(([nome, esquema_doses]) => `${nome}: ${esquema_doses}`)
            .reduce((acc, p) => acc + p, ""),
          ...produto,
        };

        this.produtos[i] = produto;
      }
    };
    const onError = (error) => {
      console.log(error);
    };
    const onEnd = () => {
      this.loading = false;
    };

    lojaService.getOne(
      {
        onSucess,
        onError,
        onEnd,
      },
      produto.id
      // itemsPerPage,
      // this.search,
      // this.options.ordenacao,
      // this.options.desc
    );
  },
};
</script>
