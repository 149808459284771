<template>
  <div id="app">
    <v-app>
      <!-- <div id="nav">
        <router-link to="/">Home</router-link> |
        <router-link to="/about">About</router-link>
      </div> -->
      <router-view />
      <div>
        <snackbar />
      </div>
    </v-app>
  </div>
</template>

<script>
import Snackbar from "@/components/widgets/snackbar.vue";

export default {
  components: {
    Snackbar,
  },

 
};

import Vue from "vue";
import VueCarousel from "vue-carousel";
Vue.use(VueCarousel);
</script>

<style>
/* App css */
@import "assets/css/bootstrap.min.css";

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

/* #nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
} */
.wpp {
  width: 5%;
  z-index: 10000;
  position: fixed;
  right: 1rem;
  bottom: 1rem;
}
.sub {
  font-size: 21px;
}
.btncall,
.btncall:hover {
  border: 2px solid #2b3a8f;
  font-weight: 500;
  font-size: 19px !important;
  padding: 1rem 5rem 1rem 5rem !important;
  margin: 2rem 0rem 4rem 0rem !important;
  border-radius: 15px;
  color: #2b3a8f !important;
}
.btncallwhite,
.btncallwhite:hover {
  border: 2px solid #fff;
  font-weight: 500;
  font-size: 19px;
  padding: 1rem 5rem 1rem 5rem;
  margin: 2rem 0rem 4rem 0rem;
  color: #fff !important;
  border-radius: 15px;
}
.senha {
  color: #fac301 !important;
  font-family: Poppins !important;
  font-size: 20px;
}
.benef {
  margin-right: 1rem;
  padding: 3% !important;
}
.taxa {
  font-family: Poppins;
}
.conta {
  font-size: 20px;
}
.appColor-primary,
.appColor-primary--text {
  color: #fac301 !important;
}
.titles {
  font-family: "Permanent Marker";
  font-size: 38px;
  line-height: 49px;
}
.appColor-secondary {
  color: #959595;
}
.appColor-blue,
.appColor-blue a {
  color: #2b3a8f !important;
}
.bgtrans,
.bgtrans:hover {
  background-color: transparent;
}

.appColor-white {
  color: #fff !important;
}

.footerColor {
  color: #dcdcdc !important;
}

.appBG-primary {
  background-color: #fac301 !important;
}

.appBG-secondary {
  background-color: #959595;
}

.appBG-white {
  background-color: #fff !important;
}

.footerBG {
  background-color: #2b3a8f !important;
}

.app-Border-primary {
  border: 1px #2b3a8f solid;
}

.app-font-size-icon {
  font-size: 0.7rem !important;
}

.margintop-menu {
  margin-top: 10rem;
}

p {
  margin-bottom: 0 !important;
}
.font {
  font-family: "Poppins";
}
.bloco {
  display: flex;
}

#tabs-calendarioVacinal .v-tabs-bar,
#tabs-calendarioVacinal .v-tabs-slider.transparent {
  background-color: transparent !important;
}

.termo-politica label {
  margin-bottom: 0;
}

.termo-politica .links {
  text-decoration: underline;
  color: rgba(0, 0, 0, 0.6);
}
.bannercall .txt {
  font-family: "Permanent Marker";
  font-size: 38px;
  line-height: 49px;
}
.somb {
  box-shadow: 0px 10px 16px #00000029 !important;
  border-radius: 16px !important;
}
.cardb {
  border: 1px solid #707070;
  border-radius: 13px;
  opacity: 1;
}
.list-group-item {
  border: 2px solid #2b3a8f;
  color: #2b3a8f;
  font-weight: 600;
  font-family: Poppins;
}
.titulologin {
  font-weight: 600;
  font-size: 17px;
}
.diaria {
  margin-top: 2rem;
}
.menu {
  margin-top: -13rem;
}
.theme--light.v-navigation-drawer {
  width: 43% !important;
}
/* RESPONSIVO IPAD AIR */
@media only screen and (min-width: 768px) and (max-width: 900px) {
  body {
    background-color: red !important;
  }
  .bgsobre img {
    width: 100% !important;
    margin-left: 0rem !important;
    margin-top: 9rem;
  }
  .imgipad {
    width: 100%;
    margin-top: 2rem;
  }
  .bannercall {
    padding: 3rem 3rem 2rem 0rem !important;
    background-position-x: -17rem;
  }
  .textbc2 {
    padding: 3rem 0rem 0rem 3rem !important;
  }
  .menu-home a {
    font-size: 20px;
  }
  .menu-home {
    margin-left: 11rem !important;
    margin-top: -4rem !important;
  }
  .theme--light.v-navigation-drawer {
    width: 75% !important;
  }
  .p-5 {
    padding: 2rem !important;
  }
  .col-8 {
    max-width: 100% !important;
  }
  .resumoipad {
    max-width: 40% !important;
  }
  .pedidos {
    width: 100%;
    height: 15%;
  }
  .titles {
    font-size: 36px;
  }
  .wpp {
    width: 12%;
  }
}
@media (max-width: 600px) {
  .theme--light.v-navigation-drawer {
    width: 84% !important;
  }
  .modal-carrinho {
    width: 20rem !important;
  }
  .wpp {
    width: 17%;
    position: fixed;
    right: 1rem;
    bottom: 1rem;
  }
  .bloco {
    display: block !important;
  }
  .banner-principal {
    height: fit-content !important;
    margin-top: 0px !important;
  }
  .margintop-menu {
    margin-top: 3em;
  }
  .titles {
    font-size: 19px;
  }
  .col-9,
  .col-3,
  .col-7,
  .col-6,
  .col-4,
  .col-8,
  .col-5 {
    max-width: 100% !important;
    width: 100% !important;
  }
  .lmenu {
    font-size: 16px;
  }
  .prod-rec {
    display: block !important;
  }
  .pedidos {
    width: 100%;
    height: 18rem;
  }
  .menu {
    margin-top: -15rem;
  }
}
</style>
