var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('nav',{staticClass:"navbar navbar-expand-lg navbar-light s-menu"},[_c('div',{staticClass:"container-fluid"},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"collapse navbar-collapse col-9",attrs:{"id":"navbarSupportedContent"}},[_c('ul',{staticClass:"navbar-nav me-auto mb-2 mb-lg-0 menu-home font appColor-blue"},[_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),(_vm.isGuest())?_c('li',{staticClass:"lmenu"},[_c('a',{attrs:{"href":"login"}},[_vm._v("Entre ou Cadastre-se")])]):_vm._e(),(!_vm.isGuest())?_c('li',{staticClass:"lmenu dropdown"},[_c('a',{staticClass:"dropdown-toggle",attrs:{"href":"#","id":"navbarDropdown","role":"button","data-bs-toggle":"dropdown","aria-expanded":"false"}},[_vm._v(" Minha Conta ")]),_c('ul',{staticClass:"dropdown-menu",attrs:{"aria-labelledby":"navbarDropdown"}},[_c('li',[_c('a',{staticClass:"dropdown-item",on:{"click":_vm.alterar_dados}},[_vm._v("Alterar Dados")])]),_c('li',[_c('a',{staticClass:"dropdown-item",on:{"click":_vm.meus_pedidos}},[_vm._v("Minhas Viagens")])]),_c('li',[_c('a',{staticClass:"dropdown-item",on:{"click":_vm.logout}},[_vm._v("Sair")])])])]):_vm._e(),_c('li',{},[_c('v-btn',{attrs:{"elevation":"0"},on:{"click":function($event){$event.stopPropagation();_vm.abrirCarrinho = !_vm.abrirCarrinho}}},[_c('v-badge',{attrs:{"color":"#2b3a8f","content":_vm.quantidade_produtos}},[_c('v-icon',{staticClass:"appColor-blue icon-font-size"},[_vm._v("mdi-basket")])],1)],1)],1)])])])]),_c('v-navigation-drawer',{staticClass:"modal-carrinho",attrs:{"fixed":"","right":"","temporary":""},model:{value:(_vm.abrirCarrinho),callback:function ($$v) {_vm.abrirCarrinho=$$v},expression:"abrirCarrinho"}},[_c('carrinho-template',{on:{"carrinhoModal":function($event){_vm.abrirCarrinho = !_vm.abrirCarrinho}}})],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"/"}},[_c('img',{attrs:{"src":require("@/assets/images/enseada-blue.png"),"alt":"..."}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"navbar-toggler",attrs:{"type":"button","data-bs-toggle":"collapse","data-bs-target":"#navbarSupportedContent","aria-controls":"navbarSupportedContent","aria-expanded":"false","aria-label":"Toggle navigation"}},[_c('span',{staticClass:"navbar-toggler-icon"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"lmenu"},[_c('a',{attrs:{"aria-current":"page","href":"../promo-detalhes-categoria"}},[_vm._v("Promoções")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"lmenu"},[_c('a',{attrs:{"href":"../detalhes-categoria"}},[_vm._v("Pacotes")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"lmenu"},[_c('a',{attrs:{"href":"/#enseada"}},[_vm._v("A Enseada")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"lmenu"},[_c('a',{attrs:{"href":"/#novidades"}},[_vm._v("Novidades")])])
}]

export { render, staticRenderFns }