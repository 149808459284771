<template>
  <div class="container-wrapper-header">
    <span>
      <v-icon
        @click="abrirMenu = !abrirMenu"
        class="icon-font-size mr-3 icon-toggle"
        >{{ abrirMenu ? "mdi-close" : "mdi-menu" }}</v-icon
      >
    </span>

    <div
      v-show="abrirMenu"
      class="app-menugeral top-header-wrapper  w-100 "
    >
      <!--<div class="top-header-wrapper-div">
        <top-header />
      </div>-->
      <div>
        <menu-loja @carrinhoModal="abrirCarrinho = !abrirCarrinho" />
      </div>
    </div>
    <v-navigation-drawer
      class="modal-carrinho"
      v-model="abrirCarrinho"
      fixed
      right
      temporary
    >
      <carrinho-template @carrinhoModal="abrirCarrinho = !abrirCarrinho" />
    </v-navigation-drawer>
  </div>
</template>

<script>
//import topHeader from "@/components/template/header/topheader/top_header.vue";
import carrinhoTemplate from "@/components/carrinho_modal/carrinho_template.vue";
import ClienteService from "@/services/cliente_service";
import VuexUsuarioMixin from "@/mixins/vuex_usuario_mixin.js";
import VuexTokenMixin from "@/mixins/vuex_token_mixin.js";

export default {
  components: {
    //topHeader,
    carrinhoTemplate,
  },

  mixins: [VuexUsuarioMixin, VuexTokenMixin],

  data: () => ({
    abrirCarrinho: false,
    abrirMenu: false,
  }),

  mounted() {
    this.checkUserValidity();
  },

  beforeMount() {
    if (!this.isMobile()) {
      this.abrirMenu = true;
    }
  },

  methods: {
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
    checkUserValidity() {
      const clienteService = new ClienteService();
      const user_id = this.$_GETTERS_usuario.id;
      clienteService.get(
        {
          onSucess: (status) => () => {
            if (status != 200) {
              // this.$_ACTIONS_setUsuario({});
              // this.$_ACTIONS_setToken("");
            }
          },

          onError: (error) => {
            console.log(error);
          },

          onEnd: () => {
            //
          },
        },
        user_id
      );
    },
  },
};
</script>

<style scoped>
.modal-carrinho {
  padding-top: 9rem;
}
.icon-toggle {
  position: fixed !important;
  left: 90vw;
  top: -30px;
}

@media (max-width: 600px) {
  .app-menugeral {
    z-index: 3000;
    margin: 0 !important;
  }
  .modal-carrinho {
    padding-top: 9rem;
    z-index: 3001;
    padding-top: 0 !important;
  }
}
</style>