<template>
  <div class="footerBG">
    <div class="margintop-menu mb-8">
      <div class="container-fluid h-100">
        <img src="@/assets/images/logo-enseada.png">
        <h2 class="appColor-white titles mb-6">BEM-VINDO!</h2>
        <div class="row d-flex">
          <div class="col-6 p-5">
            <v-card elevation="8">
              <div class="card-body p-4">
                <div class="pt-2 form-login">
                  <div class="form-group">
                    <p v-if="loginCompra" class="h5">Faça login para continuar a compra</p>
                    <p class="font titulologin">Insira seus dados para entrar na sua conta! :)</p>
                  </div>
                  <div class="form-group mb-3">
                    <v-text-field
                      color="black"
                      v-model="email"
                      type="email"
                      label="Email"
                      placeholder="Digite seu email"
                      id="EmailUsuario"
                      required
                      clearable
                    ></v-text-field>
                  </div>
                  <div class="form-group mb-3">
                    <v-text-field
                      color="black"
                      v-model="senha"
                      :append-icon="mostrarSenha ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="mostrarSenha ? 'text' : 'password'"
                      label="Senha"
                      id="SenhaUsuario"
                      placeholder="Digite sua senha"
                      @click:append="mostrarSenha = !mostrarSenha"
                    ></v-text-field>
                  </div>
                  <div class="form-group text-center">
                    <v-btn class="appBG-primary px-9 w-100" @click.prevent="login">
                      <span class="appColor-white" v-if="!loading">
                        Entrar
                      </span>
                      <v-progress-circular
                        v-else
                        indeterminate
                        color="white"
                      ></v-progress-circular>
                    </v-btn>
                  </div>
                  <div class="text-center">
                    <v-btn text @click="esqueceu_senha">
                      <span class="appColor-blue">
                        Esqueceu sua senha?
                      </span>
                    </v-btn>
                  </div>
                  <div class="form-group mt-8 mb-0 text-center">
                    <v-btn text @click="home">
                      <span class="appColor-primary">
                        Voltar para a tela inicial
                      </span>
                    </v-btn>
                  </div>
                </div>
              </div>
            </v-card>
          </div>
          <div class="col-6 p-5">
            <Nova_conta />
          </div>
        </div>
      </div>
    </div>
    <div>
      <footer-app />
    </div>
  </div>
</template>

<script>
import Mixin from "@/mixins/vuex_mixin.js";
import AuthService from "@/services/auth_service";
import footerApp from "@/components/template/footer_app.vue";
import Nova_conta from './nova_conta.vue';

export default {
  mixins: [Mixin],
  components: {
    footerApp,
    Nova_conta
  },
  data: () => ({
    email: "",
    senha: "",
    loading: false,
    mostrarSenha: false,
    loginCompra: false,
  }),
  
  beforeMount() {
   if (this.$store.state.token) {
      this.$router.push('/')
    }

    if (this.$route.params.loginCompra) {
      this.loginCompra = true
    }
  },

  methods: {
    home() {
      this.$router.replace("/");
    },
    esqueceu_senha() {
      this.$router.replace("esqueceu-senha");
    },
    criarConta() {
      this.$router.push({name: "nova-conta", params: {loginCompra: this.loginCompra}})
    },
    login() {
      //  if(this.lembrar){
      //     console.log("login")
      //     let pass = {email: this.email, senha: this.senha}
      //     this.setRemember(pass);
      //   }
      this.loading = true;
      const usuario = {
        email: this.email,
        senha: this.senha,
      };
      const authService = new AuthService();
      authService.login(
        {
          onSucess: (status) => (body) => {
            if (status === 200) {
              this.$_ACTIONS_setToken(body.data.token);
              this.$_ACTIONS_setUsuario({id: body.data.id_usuario});
              // this.DADOS_CADASTRAIS_MIXIN_getDadosCadastrais();
              if (this.loginCompra) {
                this.$router.replace({name: "checkout"});
              } else {
                this.$router.replace("meus-pedidos");
              }
            } else {
              this.$_ACTIONS_showSnackbarMessage({
                message: body.message || this.$global.messages.internalServerError,
                color: "error",
              });
            }
          },
          onError: (error) => {
            console.error(error);
            this.$_ACTIONS_showSnackbarMessage({
              message: this.$global.messages.internalServerError,
              color: "error",
            });
          },
          onEnd: () => {
            this.loading = false;
          },
        },
        usuario, 
        this.$_GETTER_perfil.description
      );
    },
  },
};
</script>
<style scoped>
  .margintop-menu {
    margin-top: 5rem;
  }
  @media (max-width: 600px) {
    .p-5{
      padding: 40px !important;
    }
    .margintop-menu{
      margin-top: 2rem;
    }
    .mb-3{
      margin-bottom: 0 !important;
    }
    .v-text-field {
      padding-top: 0px;
      margin-top: 0px;
    }
  }
</style>