<template>
  <div>
    <div id="enseada" class="bannercall2 containe mt-5">
      <div class="col-7 textbc2">
        <p class="txt appColor-blue">A ENSEADA</p>
        <p class="appColor-blue font sub mt-4">
          Em meados dos anos 2000, sentindo a necessidade em criar uma empresa
          que oferecesse um serviço diferenciado das demais, mais familiar e
          próximo do cliente, a Enseada Turismo nasceu. Seguimos a diretriz de
          "realizando o seu sonho, também realizamos o nosso". Esse fator foi
          preponderante para criarmos um laço que existe até hoje e que chamamos
          de "Família Enseada".
        </p>
        <p class="txt appColor-blue mt-5">NOSSOS DIFERENCIAIS</p>
        <p class="font sub appColor-blue mt-2">
          <a href="detalhes-categoria">Melhores Preços</a> |
          <a href="detalhes-categoria">Viagem Completa</a> |
          <a href="detalhes-categoria">Condições especiais</a>
        </p>
      </div>
      <div class="col-5 bgsobre">
        <img class="" src="@/assets/images/sobre.png" />
      </div>
    </div>
  </div>
</template>
<script>
import bgS from "@/assets/images/fundo2.png";
export default {
  components: {},
  data() {
    return {
      bgSobre: bgS,
    };
  },
};
</script>

<style scoped>
.bannercall2 .txt {
  font-family: "Permanent Marker";
  font-size: 38px;
  line-height: 49px;
}
.bannercall2 {
  text-align: left;
  display: flex;
}

.fblue {
  background-color: #2b3a8f;
}
.bgsobre img {
  width: 70%;
  margin-left: 20rem;
}
.textbc2 {
  padding: 5rem 0rem 3rem 8rem;
}
@media (max-width: 600px) {
  .bgsobre img {
    width: 70%;
    margin-left: 0rem;
  }
  .bgsobre {
    display: none;
  }
  .bannercall2 {
    text-align: center;
    display: block;
  }
  .textbc2 {
    padding: 3rem 2rem 3rem 2rem;
  }
}
</style>
