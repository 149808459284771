<template>
  <div>
    <div
      id="novidades"
      class="bannercall appColor-blue"
      :style="'background-image: url(' + bgText + ');'"
    >
      <p class="txt">Fique por dentro das novidades</p>
      <p class="sub font">e dicas imperdíveis no nosso Instagram</p>
      <a
        class="btn btn-primary bgtrans font btncall"
        target="_blank"
        href="https://www.instagram.com/enseada.turismo/"
        >Siga nosso Instagram</a
      >
    </div>
  </div>
</template>
<script>
import bgT from "@/assets/images/banner3.png";
export default {
  components: {},
  data() {
    return {
      bgText: bgT,
    };
  },
};
</script>

<style scoped>
.bannercall {
  text-align: right;
  background-size: cover;
  padding: 3rem 11rem 2rem 0rem;
}

@media (max-width: 600px) {
  .btncall,
  .btncall:hover {
    border: 2px solid #2b3a8f;
    background-color: #2b3a8f;
    color: #fff !important;
  }

  .bannercall {
    text-align: center;
    background-size: 223rem;
    background-position-x: -66rem;
    padding: 3rem 1rem 2rem 1rem;
  }
}
</style>