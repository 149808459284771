<template>
  <div>
    <div>
      <Header_geral />
    </div>
    <div>
      <Menu_int />
    </div>
    <div class="d-flex container labels m-auto">
      <div class="col">
        <h3 class="titles appColor-blue">PACOTES</h3>
      </div>
      <div class="col text-left">
        <!-- <div class="bloco3">
          <p>Pesquise por categoria:</p>
          <v-autocomplete
            class="w-100"
            v-model="categorias_produto"
            :items="categorias"
            :loading="loadingCategorias"
            :search-input.sync="search_category"
            item-text="nome"
            @blur="getProdutos"
            item-value="categoria_id"
            placeholder="Pesquise uma categoria"
          >
            <template v-slot:selection="data">
              <v-chip
                v-bind="data.attrs"
                :input-value="data.selected"
                @click="data.select"
                @click:close="remover(data.item)"
              >
                {{ data.item.nome }}
              </v-chip>
            </template>
          </v-autocomplete>
        </div> -->
      </div>
      <!-- <div class="col-3 d-flex text-left">
        <div class="bloco3">
          <p>Datas</p>
          <v-text-field
            v-model="dateRangeText"
            label="Ida ~ Volta"
            readonly
            color="#FAC301"
            @click="abrir = !abrir"
          ></v-text-field>
        </div>
      </div> -->
      <div class="col d-flex help">
        <div class="mr-5">
          <v-icon class="appColor-blue iconhelp"> mdi-help</v-icon>
        </div>
        <div>
          <a
            href="https://api.whatsapp.com/send?phone=5585996081221&text=Ol%C3%A1%2C%20vi%20o%20site%20e%20quero%20mais%20infoma%C3%A7%C3%B5es%20sobre%20os%20pacotes%20de%20turismo!"
            target="_blank"
          >
            <h4 class="appColor-blue font">Precisa de ajuda?</h4>
          </a>
        </div>
      </div>
    </div>
    <div>
      <div>
        <v-date-picker
          v-model="dates"
          v-if="abrir"
          color="#FAC301"
          range
        ></v-date-picker>
      </div>
      <div class="d-flex container" v-if="dados[0].data.length > 0">
        <div class="col-9 m-auto">
          <template> </template>
          <v-card class="d-flex mt-5" v-for="(produto, i) in produtos" :key="i">
            <div class="col-3">
              <v-img
                :src="produto.src"
                class="text-center image"
                aspect-ratio="2"
                cover
              ></v-img>
            </div>
            <div class="col-9 d-flex">
              <div class="col text-left">
                <h3>{{ produto.titulo }}</h3>
                <!-- <h4 class="appColor-blue font">
                  {{ produto.descricao }}
                </h4> -->
              </div>
              <div class="col text-right">
                <h4 class="font appColor-blue">
                  {{
                    produto.valorAtual
                      | money({
                        prefix: "R$ ",
                        suffix: "",
                        thousands: ".",
                        decimal: ",",
                        precision: 2,
                      })
                  }}
                </h4>
                <!-- <p class="font-weight-bold mb-0" v-if="produto.parcelado">
                  ou {{ produto.quantidade_parcelas }}x de R${{
                    produto.preco_parcelado
                  }}
                </p>
                <p class="font appColor-blue">
                  em atÃ© 12x de R$ 187,29 s/ juros no cartÃ£o de crÃ©dito
                </p> -->
                <v-btn
                  class="appBG-primary appColor-white mt-2 btnslide"
                  :href="produto.href"
                  @click="detalhesProduto(produto.produtoId)"
                >
                  Saiba mais
                </v-btn>
              </div>
            </div>
          </v-card>
          <v-pagination
            class="pag"
            v-model="page"
            :length="num_pages"
            :total-visible="7"
            @input="getProdutos"
          ></v-pagination>
        </div>
      </div>
    </div>
    <div>
      <footerApp />
    </div>
  </div>
</template>
<script>
import Header_geral from "../components/template/header/header_geral.vue";
import Menu_int from "../components/template/header/navbar/menu_int.vue";
import footerApp from "@/components/template/footer_app.vue";
import dados from "./../data/data.json";
import LojaService from "@/services/loja_service";
import CategoriaService from "@/services/categoria_service.js";

export default {
  mounted() {
    this.getProdutos();
    this.getAll();
    this.loadCategorias();
  },
  data() {
    return {
      produtos: [],
      dados: dados,
      loadingCategorias: false,
      categorias: [],
      // categorias_produto: [],
      categorias_produto: "",
      search_category: "",
      dates: [
        new Date().toISOString().substr(0, 10),
        new Date().toISOString().substr(0, 10),
      ],
      num_pages: 5,
      per_page: 5,
      ordenacao: "",
      desc: false,
      page: 1,
      abrir: false,
    };
  },
  beforeMount() {
    console.log(this.produto);
    this.loadCategorias();
  },
  computed: {
    dateRangeText() {
      return this.dates.join(" ~ ");
    },
  },

  components: {
    Header_geral,
    Menu_int,
    footerApp,
  },

  watch: {},
  methods: {
    getProdutos() {
      this.loading = true;
      console.log("entrou get produtos");
      console.log("this.categorias_produtos: ", this.categorias_produto);

      const lojaService = new LojaService();
      this.produtos = [];
      const onSucess = (status) => (body) => {
        if (status === 200) {
          console.log("body: ", body.data);

          this.num_pages = body.data.pagination.last_page;
          this.produtos = body.data.loja.map((pack) => ({
            // src: require("@/assets/images/produto.png"),
            src: pack.foto ?? require("../assets/images/produto.png"),
            titulo: pack.nome,
            // junta o titulo dos produtos
            descricao: pack.produtos.reduce(
              (acc, p) => acc + ` - ${p.produto}`,
              ""
            ),
            promocao: true,
            // valorAnterior: "R$ 350",
            // valorAtual: pack.preco,
            valorAtual: String(parseFloat(pack.preco).toFixed(2)).replace(
              ".",
              ","
            ),
            parcelado: false,
            quantidade_parcelas: "",
            // preco_parcelado: "",
            href: `/detalhes-produto/${pack.pacote_id}`,
          }));
          //this.produtos = this.produtos.slice(0, 4);
          //this.produtos.map( (p, i) => this.getProdutos(p, i))
        } else {
          // console.log(body);
        }
      };
      const onError = (error) => {
        console.log("Erro:", error);
      };
      const onEnd = () => {
        this.loading = false;
        console.log("end");
      };

      if (this.categorias_produto !== "") {
        let filtro = this.categorias_produto;
        lojaService.getAll(
          onSucess,
          onError,
          onEnd,
          this.page,
          this.per_page,
          filtro,
          this.desc
        );
      } else {
        lojaService.getAll(
          onSucess,
          onError,
          onEnd,
          this.page,
          this.per_page,
          "",
          this.desc
        );
      }
    },
    detalhesProduto(produtoId) {
      this.$router.replace({
        name: "detalhes-produto",
        params: { produtoId: produtoId },
      });
    },
    loadCategorias() {
      this.loadingCategorias = true;
      const categoriaService = new CategoriaService();
      this.categorias = [];
      const onSucess = (status) => (body) => {
        if (status === 200) {
          let categoriasLista = body.data.categorias;
          this.categorias = categoriasLista.map((categoria) => categoria.nome);
          console.log("this.categorias: ", this.categorias);
        } else {
          console.log(body);
        }
      };
      const onError = (error) => {
        console.log(error);
      };
      const onEnd = () => {
        this.loadingCategorias = false;
      };

      categoriaService.getList({ onSucess, onError, onEnd });
    },
    remover(item) {
      const index = this.categorias_produto.indexOf(item.categoria_id);
      console.log(index);
      if (index >= 0) this.categorias_produto.splice(index, 1);
    },
    getProduto(produto, i) {
      this.loading = true;
      const lojaService = new LojaService();

      const onSucess = (status) => (body) => {
        if (status === 200) {
          // this.totalList = body.data.pagination.num_rows;
          const produtoReq = body.data.loja[0];
          console.log(body.data);
          produto = {
            prevencao: produtoReq.produtos
              .map((p) => p.prevencao)
              .filter((p) => p != null && p != ""),
            indicacoes: produtoReq.produtos
              .map((p) => p.indicacoes)
              .filter((p) => p != null),
            recomendacoes: produtoReq.produtos
              .map((p) => p.recomendacoes)
              .filter((p) => p != null),
            contraindicacoes: produtoReq.produtos
              .map((p) => p.contra_indicacoes)
              .filter((p) => p != null),
            esquemaDoses: produtoReq.produtos
              .map((p) => [p.nome, p.esquema_doses])
              .filter(
                ([nome, esquema_doses]) => nome != null && esquema_doses != null
              )
              .map(([nome, esquema_doses]) => `${nome}: ${esquema_doses}`)
              .reduce((acc, p) => acc + p, ""),
            ...produto,
          };

          this.produtos[i] = produto;
        }
      };
      const onError = (error) => {
        console.log(error);
      };
      const onEnd = () => {
        this.loading = false;
      };

      lojaService.getOne(
        {
          onSucess,
          onError,
          onEnd,
        },
        produto.id
        // itemsPerPage,
        // this.search,
        // this.options.ordenacao,
        // this.options.desc
      );
    },
  },

  filters: {},
};
</script>
<style scoped>
.valor {
  width: 91% !important;
}
.v-application .primary,
.v-application .primary.lighten-3 {
  background-color: #2b3a8f !important;
  border-color: #2b3a8f !important;
}

.blocfilter {
  background-color: #e8e8e8;
  text-align: left;
  color: #434343;
  padding: 3%;
}
.fontw {
  font-weight: 700;
}
.btnprod {
  height: 26%;
  font-size: 16px;
}
.imgproduct {
  width: 100%;
}
.iconhelp {
  border: 2px solid #2b3a8f;
  border-radius: 14px;
  padding: 1%;
}
.labels,
label {
  color: #2b3a8f;
  font-weight: 600;
}
.bloco1 {
  border: 1px solid #707070;
  border-radius: 8px 0px 0px 8px;
  opacity: 1;
  padding: 2% 2% 0% 2%;
}
.bloco2 {
  border: 1px solid #707070;
  border-radius: 0px 8px 8px 0px;
  opacity: 1;
  padding: 2% 2% 0% 2%;
}
.bloco3 {
  border: 1px solid #707070;
  border-radius: 8px 8px 8px 8px;
  opacity: 1;
  padding: 2% 2% 0% 2%;
}
.v-picker {
  margin: -1rem -27rem -24rem 1rem;
  z-index: 2;
}
.pag {
  margin-top: 2rem;
}
@media (max-width: 600px) {
  .labels {
    display: block !important;
  }
  .help {
    justify-content: space-around;
  }
  .v-application .d-flex {
    display: block !important;
  }
}
</style>
