<template>
  <div>
    <div class="row">
      <div class="col-sm-9">
        <h4 class="text-left font-weight-bold">
          {{ item.titulo }} - {{ getDataHoje(item) }}
        </h4>
        <h3 class="appColor-primary font-weight-bold text-left">
        R$
          {{
            (item.valorTotal = 
              item.valorUnitarioCrianca1 * item.quantidadeCrianca1 +
              item.valorUnitario * item.quantidade +
              item.valorUnitarioCrianca2 * item.quantidadeCrianca2)
              | money({
                prefix: "",
                suffix: "",
                thousands: ".",
                decimal: ",",
                precision: 2,
              })
          }}
        </h3>

        <div class="contador d-flex align-items-center">
          <span class="mr-5">Adultos</span>
          <v-icon
            class="app-Border-primary appColor-primary"
            @click="$emit('diminuirQuantidade')"
            :disabled="item.quantidade <= 1 ? true : false"
          >
            mdi-minus
          </v-icon>
          <h3 class="font-weight-light mx-2">
            {{ item.quantidade }}
          </h3>
          <v-icon
            class="app-Border-primary appColor-primary"
            @click="$emit('aumentarQuantidade')"
          >
            mdi-plus
          </v-icon>

          <h4 class="ml-5">
                  R$
                    {{
                      parseFloat(item.valorUnitario*item.quantidade)
                        | money({
                          prefix: "",
                          suffix: "",
                          thousands: ".",
                          decimal: ",",
                          precision: 2,
                        })
                    }}
          </h4>
        </div>

        <div class="contador d-flex align-items-center">
                <input type="checkbox" v-model="item.checkedCrianca1">
                <span class="mx-5">Crianças 0-4 anos</span>
                <v-icon
                  class="app-Border-primary appColor-primary"
                  @click="item.quantidadeCrianca1--"
                  :disabled="item.quantidadeCrianca1 <= 0 || !item.checkedCrianca1 ? true : false"
                >
                  mdi-minus
                </v-icon>
                  <h3 class="font-weight-light mx-2">{{item.checkedCrianca1 ? item.quantidadeCrianca1 : item.quantidadeCrianca1=0}}</h3>
                <v-icon
                  class="app-Border-primary appColor-primary"
                  @click="item.quantidadeCrianca1++"
                  :disabled="!item.checkedCrianca1 ? true : false"
                >
                  mdi-plus
                </v-icon>
                <h4 class="ml-5">
                  R$
                    {{
                      parseFloat(item.valorUnitarioCrianca1*item.quantidadeCrianca1)
                        | money({
                          prefix: "",
                          suffix: "",
                          thousands: ".",
                          decimal: ",",
                          precision: 2,
                        })
                    }}
                </h4>
        </div>

        <div class="contador d-flex align-items-center">
                <input type="checkbox" v-model="item.checkedCrianca2">
                <span class="mx-5">Crianças 5-12 anos</span>
                <v-icon
                  class="app-Border-primary appColor-primary"
                  @click="item.quantidadeCrianca2--"
                  :disabled="item.quantidadeCrianca2 <= 0 || !item.checkedCrianca2 ? true : false"
                >
                  mdi-minus
                </v-icon>
                  <h3 class="font-weight-light mx-2">{{item.checkedCrianca2 ? item.quantidadeCrianca2 : item.quantidadeCrianca2=0}}</h3>
                <v-icon
                  class="app-Border-primary appColor-primary"
                  @click="item.quantidadeCrianca2++"
                  :disabled="!item.checkedCrianca2 ? true : false"
                >
                  mdi-plus
                </v-icon>
                <h4 class="ml-5">
                  R$
                    {{
                      parseFloat(item.valorUnitarioCrianca2*item.quantidadeCrianca2)
                        | money({
                          prefix: "",
                          suffix: "",
                          thousands: ".",
                          decimal: ",",
                          precision: 2,
                        })
                    }}
                </h4>
        </div>


      </div>
      <div class="col-sm-3">
        <v-btn class="appColor-secondary" @click="$emit('removerItem')" plain>
          Remover
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: Object,
  },
  mounted() {
    this.item.valorTotal = 
      this.item.valorUnitarioCrianca1 * this.item.quantidadeCrianca1 +
      this.item.valorUnitario * this.item.quantidade +
      this.item.valorUnitarioCrianca2 * this.item.quantidadeCrianca2;
  },

  methods: {
    getDataHoje(item) {
      if (item.data) {
        return new Date(item.data).toLocaleDateString("pt-BR");
      } else {
        return "";
      }
    },
  },
};
</script>

<style scoped>
.v-icon--disabled {
  border: 1px solid #cdcdcd;
}
</style>

