<template>
  <div>
    <div>
      <header-geral />
    </div>
    <div>
      <Menu_int />
    </div>
    <div class="mt-10">
      <div class="container">
        <div class="historico text-left">
          <router-link class="font-weight-bold mr-3 text-dark" to="/"
            >Home</router-link
          >
          <router-link
            class="font-weight-bold appColor-primary"
            :to="`/detalhes-produto/${produtoId}`"
          >
            <span class="mr-3">></span>
            <span>Produto</span>
          </router-link>
        </div>
        <!--NOVO LAYOUT-->
        <div>
          <h2 class="titles appColor-blue text-left">{{ produto.titulo }}</h2>
          <!-- <p class="font font-info mb-5 text-left">
            {{ produto.esquemaDoses.join("") }}
          </p> -->
          <!--<p class="font font-info mb-5 text-left" v-for="(previne, i) in produto.prevencao"
                  :key="i"> {{ previne }} | <a href="#">Ver no mapa</a></p>-->
          <div class="row pacote">
            <div class="col-6">
              <v-img
                :src="produto.src"
                class="img-fluid pacImage"
                aspect-ratio="2"
                cover
                height="95%"
              ></v-img>
            </div>
            <div class="col-6 text-center bloc-info">
              <p class="font font-info">Seu passeio</p>

              <div>
                <!-- <div class="benef">
                  <v-icon>mdi-bed-king-outline</v-icon>
                  <span>hospedagem</span>
                </div> -->

                <!--<div class="benef">
                            <v-icon>mdi-bed-king-outline</v-icon>
                            <span>{{ produto.recomendacoes.join(" - ") }}</span>
                        </div>

                        <div class="benef">
                            <v-icon>mdi-bed-king-outline</v-icon>
                            <span>{{ produto.esquemaDoses.join(" - ") }}</span>
                        </div>-->
              </div>

              <div class="row mt-5">
                <div class="text-left col-6">
                  <p class="font font-info">Valor:</p>
                  <p class="font font-info">
                    Data:
                    <span v-if="selectedDate">
                      {{ new Date(selectedDate).toLocaleDateString("pt-BR") }}
                    </span>
                    <a
                      v-else
                      class="text-warning"
                      style="cursor: pointer"
                      href="#calendario"
                    >
                      Selecione uma data
                    </a>
                  </p>
                  <p class="senha">
                    {{ quantidade + quantidadeCrianca1 + quantidadeCrianca2 }}
                    /passeios
                  </p>
                </div>
                <div class="text-right col-6">
                  <span class="titles appColor-blue"
                    >R$
                    {{
                      parseFloat(produto.valorTotal)
                        | money({
                          prefix: "",
                          suffix: "",
                          thousands: ".",
                          decimal: ",",
                          precision: 2,
                        })
                    }}
                  </span>
                </div>
              </div>
              <div class="contador d-flex align-items-center">
                <span class="mr-5">Adultos</span>
                <v-icon
                  class="app-Border-primary appColor-primary"
                  @click="quantidade--"
                  :disabled="quantidade <= 1 ? true : false"
                >
                  mdi-minus
                </v-icon>
                <h3 class="font-weight-light mx-2">{{ quantidade }}</h3>
                <v-icon
                  class="app-Border-primary appColor-primary"
                  @click="quantidade++"
                >
                  mdi-plus
                </v-icon>
                <h4 class="ml-5">
                  R$
                  {{
                    parseFloat(produto.valorAtual * quantidade)
                      | money({
                        prefix: "",
                        suffix: "",
                        thousands: ".",
                        decimal: ",",
                        precision: 2,
                      })
                  }}
                </h4>
              </div>

              <div class="contador d-flex align-items-center">
                <input type="checkbox" v-model="checkedCrianca1" />
                <span class="mx-5">Crianças 0-4 anos</span>
                <v-icon
                  class="app-Border-primary appColor-primary"
                  @click="quantidadeCrianca1--"
                  :disabled="
                    quantidadeCrianca1 <= 0 || !checkedCrianca1 ? true : false
                  "
                >
                  mdi-minus
                </v-icon>
                <h3 class="font-weight-light mx-2">
                  {{
                    checkedCrianca1
                      ? quantidadeCrianca1
                      : (quantidadeCrianca1 = 0)
                  }}
                </h3>
                <v-icon
                  class="app-Border-primary appColor-primary"
                  @click="quantidadeCrianca1++"
                  :disabled="!checkedCrianca1 ? true : false"
                >
                  mdi-plus
                </v-icon>
                <h4 class="ml-5">
                  R$
                  {{
                    parseFloat(produto.valorAtualCrianca1 * quantidadeCrianca1)
                      | money({
                        prefix: "",
                        suffix: "",
                        thousands: ".",
                        decimal: ",",
                        precision: 2,
                      })
                  }}
                </h4>
              </div>

              <div class="contador d-flex align-items-center">
                <input type="checkbox" v-model="checkedCrianca2" />
                <span class="mx-5">Crianças 5-12 anos</span>
                <v-icon
                  class="app-Border-primary appColor-primary"
                  @click="quantidadeCrianca2--"
                  :disabled="
                    quantidadeCrianca2 <= 0 || !checkedCrianca2 ? true : false
                  "
                >
                  mdi-minus
                </v-icon>
                <h3 class="font-weight-light mx-2">
                  {{
                    checkedCrianca2
                      ? quantidadeCrianca2
                      : (quantidadeCrianca2 = 0)
                  }}
                </h3>
                <v-icon
                  class="app-Border-primary appColor-primary"
                  @click="quantidadeCrianca2++"
                  :disabled="!checkedCrianca2 ? true : false"
                >
                  mdi-plus
                </v-icon>
                <h4 class="ml-5">
                  R$
                  {{
                    parseFloat(produto.valorAtualCrianca2 * quantidadeCrianca2)
                      | money({
                        prefix: "",
                        suffix: "",
                        thousands: ".",
                        decimal: ",",
                        precision: 2,
                      })
                  }}
                </h4>
              </div>

              <div>
                <!-- <a
                  href="https://api.whatsapp.com/send?phone=5585996081221&text=Ol%C3%A1%2C%20vi%20o%20site%20e%20quero%20mais%20infoma%C3%A7%C3%B5es%20sobre%20os%20pacotes%20de%20turismo!"
                  target="_blank"
                >
                  <v-btn class="appBG-primary appColor-white mt-2 ml-3">
                    <v-icon left> mdi-basket </v-icon>
                    Adicionar ao carrinho
                  </v-btn>
                </a> -->

                <v-btn
                  class="appBG-primary appColor-white mt-2 ml-3"
                  @click="adicionaraoCarrinho"
                >
                  <v-icon left> mdi-basket </v-icon>
                  Adicionar ao carrinho
                </v-btn>
              </div>

              <hr class="barra" />
              <div>
                <!-- <span class="taxa font font-info">
                  Taxas e impostos inclusos | <b>até 12x</b>
                </span> -->
                <a
                  class="btn btn-primary appColor-blue bgtrans font btncall"
                  href="https://api.whatsapp.com/send?phone=5585996081221&text=Ol%C3%A1%2C%20vi%20o%20site%20e%20quero%20mais%20infoma%C3%A7%C3%B5es%20sobre%20os%20pacotes%20de%20turismo!"
                  target="_blank"
                >
                  Precisa de ajuda?</a
                >
              </div>
            </div>
          </div>

          <h2 class="titles appColor-blue mt-3 text-left">SOBRE O PASSEIO</h2>

          <div class="px-2 mb-6" v-if="produto.prevencao.length > 0">
            <h4 class="text-left font-weight-bold">Roteiro da viagem:</h4>
            <p
              class="text-left exibir"
              v-for="(previne, i) in produto.prevencao"
              :key="i"
            >
              {{ previne }}
            </p>
          </div>
          <div class="px-2 mb-6" v-if="produto.indicacao">
            <h4 class="text-left font-weight-bold">Importante saber:</h4>
            <p
              class="text-left exibir"
              v-for="(indicacao, i) in produto.indicacao"
              :key="i"
            >
              {{ indicacao }}
            </p>
          </div>
          <div class="px-2 mb-4" v-if="produto.esquemaDoses">
            <h4 class="text-left font-weight-bold">Paradas:</h4>
            <pre
              class="text-left exibir"
              v-for="(esquemaDoses, i) in produto.esquemaDoses"
              :key="i"
              >{{ esquemaDoses }}
            </pre>
          </div>
          <div class="px-2 mb-6" v-if="produto.saida">
            <h4 class="text-left font-weight-bold">Saída:</h4>
            <p class="text-left exibir">{{ produto.saida }}</p>
          </div>

          <h2 class="titles appColor-blue mt-5 mb-3 text-left" id="calendario">
            AGENDA
          </h2>
          <!-- <input type="date" v-model="data_atual" @change="getAgenda" /> -->
          <v-sheet tile height="70" class="d-flex">
            <v-btn icon class="ma-2" @click="$refs.calendar.prev()">
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-toolbar-title
              class="titles appColor-blue mt-5 mb-3 text-center"
              v-if="$refs.calendar"
            >
              {{ $refs.calendar.title }}
            </v-toolbar-title>
            <!-- <input class="titles appColor-blue mt-5 mb-3 text-center" type="text" v-model="mes_atual" @change="getAgenda" /> -->
            <v-spacer></v-spacer>
            <v-btn icon class="ma-2" @click="$refs.calendar.next()">
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </v-sheet>

          <v-sheet height="600">
            <v-calendar
              ref="calendar"
              v-model="value"
              :weekdays="weekday"
              :type="type"
              :events="events"
              :event-overlap-mode="mode"
              :event-overlap-threshold="30"
              :event-color="getEventColor"
              @change="getEvents"
              @click:event="showEvent"
              @click:more="viewDay"
              @click:date="viewDay"
            >
            </v-calendar>
            <v-menu
              v-model="selectedOpen"
              :close-on-content-click="false"
              :activator="selectedElement"
              offset-x
            >
              <v-card color="grey lighten-4" min-width="350px" flat>
                <v-toolbar :color="selectedEvent.color" dark>
                  <v-toolbar-title>
                    <p>Confirme abaixo:</p>
                  </v-toolbar-title>
                  <v-spacer></v-spacer>
                </v-toolbar>
                <v-card-text>
                  <span v-html="selectedEvent.details"></span>
                </v-card-text>
                <v-card-actions>
                  <v-btn
                    text
                    color="primary"
                    @click="selectDateOpen(selectedEvent.start)"
                  >
                    Selecionar data
                  </v-btn>
                  <v-btn text color="secondary" @click="selectedOpen = false">
                    Cancel
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-menu>
          </v-sheet>
        </div>
      </div>
    </div>
    <div>
      <detalhe-pacote />
    </div>
    <div class="mt-16">
      <produtos-recomendados />
    </div>
    <div class="mt-16">
      <footer-app />
    </div>
  </div>
</template>

<script>
import headerGeral from "@/components/template/header/header_geral.vue";
import produtosRecomendados from "@/components/detalhes_produto/produtos_recomendados.vue";
import footerApp from "@/components/template/footer_app.vue";
import CarrinhoMixin from "@/mixins/carrinho_mixin.js";
import LojaService from "../services/loja_service.js";
import Mixin from "@/mixins/vuex_mixin.js";
import Menu_int from "../components/template/header/navbar/menu_int.vue";
import DetalhePacote from "../views/detalhe_pacote.vue";

export default {
  mixins: [CarrinhoMixin, Mixin],
  components: {
    headerGeral,
    produtosRecomendados,
    DetalhePacote,
    Menu_int,
    footerApp,
  },
  data: () => ({
    checkedCrianca1: false,
    checkedCrianca2: false,
    quantidade: 1,
    quantidadeCrianca1: 0,
    quantidadeCrianca2: 0,
    data_atual: new Date(),
    mes_atual: "",
    agenda: [],
    saida: "",
    selectedDate: false,
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
    produto: {
      esquemaDoses: [],
      prevencao: [],
      indicacao: [],
    },
    type: "month",
    types: ["month"],
    mode: "stack",
    modes: ["stack", "column"],
    mes_agenda: [
      "Janeiro",
      "Fevereiro",
      "Março",
      "Abril",
      "Maio",
      "Junho",
      "Julho",
      "Agosto",
      "Setembro",
      "Outubro",
      "Novembro",
      "Dezembro",
    ],
    weekday: [0, 1, 2, 3, 4, 5, 6],
    weekdays: [
      { text: "Sun - Sat", value: [0, 1, 2, 3, 4, 5, 6] },
      { text: "Mon - Sun", value: [1, 2, 3, 4, 5, 6, 0] },
      { text: "Mon - Fri", value: [1, 2, 3, 4, 5] },
      { text: "Mon, Wed, Fri", value: [1, 3, 5] },
    ],
    value: "",
    events: [],
    colors: [
      "blue",
      "indigo",
      "deep-purple",
      "cyan",
      "green",
      "orange",
      "grey darken-1",
    ],
    names: [
      "Meeting",
      "Holiday",
      "PTO",
      "Travel",
      "Event",
      "Birthday",
      "Conference",
      "Party",
    ],
  }),
  beforeMount() {
    this.getAgenda();

    this.getProduto();
    if (
      this.$route.params.produtoId !== "" &&
      this.$route.params.produtoId !== undefined
    ) {
      this.produtoId = this.$route.params.produtoId;
    } else {
      this.$router.replace({ path: "/" });
    }
  },
  mounted() {
    this.produto.valorTotal = this.produto.valorAtual * this.quantidade;
  },
  watch: {
    quantidade() {
      this.produto.valorTotal =
        this.produto.valorAtualCrianca1 * this.quantidadeCrianca1 +
        this.produto.valorAtual * this.quantidade +
        this.produto.valorAtualCrianca2 * this.quantidadeCrianca2;
    },
    quantidadeCrianca1() {
      this.produto.valorTotal =
        this.produto.valorAtualCrianca1 * this.quantidadeCrianca1 +
        this.produto.valorAtual * this.quantidade +
        this.produto.valorAtualCrianca2 * this.quantidadeCrianca2;
    },
    quantidadeCrianca2() {
      this.produto.valorTotal =
        this.produto.valorAtualCrianca1 * this.quantidadeCrianca1 +
        this.produto.valorAtual * this.quantidade +
        this.produto.valorAtualCrianca2 * this.quantidadeCrianca2;
    },
  },
  methods: {
    formatDataDb(data) {
      let [mes, dia, ano] = data.split("/");

      if (dia < 10) {
        dia = "0" + dia;
      }

      if (mes < 10) {
        mes = "0" + mes;
      }

      return `${ano}-${mes}-${dia}`;
    },

    selectDateOpen(ev) {
      this.selectedDate = new Date(ev).toLocaleDateString("en-US");
      this.selectedOpen = false;

      this.$_ACTIONS_showSnackbarMessage({
        message: "Data selecionada",
        color: "sucess",
      });

      window.scroll(0, 0);
    },

    adicionaraoCarrinho() {
      let carrinho = {
        titulo: this.produto.titulo,
        valorTotal: this.produto.valorTotal,
        valorUnitario: this.produto.valorAtual,
        valorUnitarioCrianca1: this.produto.valorAtualCrianca1,
        valorUnitarioCrianca2: this.produto.valorAtualCrianca2,
        quantidade: this.quantidade,
        quantidadeCrianca1: this.quantidadeCrianca1,
        quantidadeCrianca2: this.quantidadeCrianca2,
        checkedCrianca1: this.checkedCrianca1,
        checkedCrianca2: this.checkedCrianca2,
        data: this.selectedDate,
        id: `${this.produto.produtoId}:${this.selectedDate}`,
      };
      if (this.selectedDate) {
        this.$_ACTIONS_carrinho(carrinho);
        this.$_ACTIONS_showSnackbarMessage({
          message: "Adicionado ao carrinho",
          color: "sucess",
        });
      } else {
        const height = this.$el.clientHeight * 0.4;
        console.log(height);
        window.scroll(0, height);
        this.$_ACTIONS_showSnackbarMessage({
          message: "Por favor selecione uma data.",
          color: "error",
        });
      }
    },

    viewDay({ date }) {
      this.calendario.focus = date;
      // this.calendario.typeView = "day";
    },

    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        requestAnimationFrame(() =>
          requestAnimationFrame(() => (this.selectedOpen = true))
        );
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },

    getEvents() {
      this.events = this.agenda.map((item) => ({
        name: "Selecione o dia",
        start: new Date(item.dia),
        end: new Date(item.dia),
        color: "green darken-2",
        allDay: false,
      }));
    },

    getEventColor(event) {
      return event.color;
    },

    getAgenda() {
      this.loading = true;
      let mes = new Date(this.data_atual).getMonth() + 1;
      let ano = new Date(this.data_atual).getFullYear();
      let mes_atual = new Date(this.data_atual).getMonth();
      this.mes_atual = this.mes_agenda[mes_atual];
      const lojaService = new LojaService();

      const onSucess = (status) => (body) => {
        if (status === 200) {
          const agenda = body.data.loja.map((data) => {
            const d = data.data.split(" ");
            const res = new Date(d[0]);
            const dia = new Date(res.toISOString().slice(0, -1));
            data.dia = dia;
            data.allDay = true;
            data.active = false;
            return data;
          });
          this.agenda = agenda;
        }
      };

      const onError = (error) => {
        console.log(error);
      };

      const onSucess2 = (status) => (body) => {
        if (status === 200) {
          const agenda = body.data.loja.map((data) => {
            const dia = new Date(data.data.split(" ")[0]);
            data.dia = dia;
            data.allDay = true;
            return data;
          });

          agenda.forEach((a) => this.agenda.push(a));
        }
      };
      const onEnd = () => {
        this.loading = false;
      };

      lojaService.getAgenda(
        onSucess,
        onError,
        onEnd,
        this.$route.params.produtoId,
        1,
        mes < 10 ? `0${mes}` : mes,
        ano
      );

      if (mes >= 12) {
        mes = 1;
        ano = ano++;
      } else {
        mes++;
      }

      const onEnd2 = () => {
        this.loading = false;
        this.getEvents();
      };

      lojaService.getAgenda(
        onSucess2,
        onError,
        onEnd2,
        this.$route.params.produtoId,
        1,
        mes < 10 ? `0${mes}` : mes,
        ano
      );
    },

    getProduto() {
      this.loading = true;
      const lojaService = new LojaService();

      const onSucess = (status) => (body) => {
        if (status === 200) {
          // this.totalList = body.data.pagination.num_rows;
          const produto = body.data.loja[0];
          console.log(body.data);
          this.produto = {
            produtoId: this.$route.params.produtoId,
            src: produto.foto ?? require("@/assets/images/produto.png"),
            titulo: produto.nome,
            saida: produto.saida,
            descricao: produto.produtos
              .reduce((acc, p) => acc + ` - ${p.produto}`, "")
              .slice(2),
            valorAtual: produto.preco,
            valorAtualCrianca1: produto.preco_1,
            valorAtualCrianca2: produto.preco_2,
            valorTotal: produto.preco,
            prevencao: produto.produtos
              .map((p) => p.prevencao)
              .filter((p) => p != null),
            indicacao: produto.produtos
              .map((p) => p.indicacao)
              .filter((p) => p != null),
            esquemaDoses: produto.produtos
              .map((p) => p.esquema_doses)
              .filter((esquema_doses) => esquema_doses != null),
          };
        }
      };
      const onError = (error) => {
        console.log(error);
      };
      const onEnd = () => {
        this.loading = false;
      };

      lojaService.getOne(
        {
          onSucess,
          onError,
          onEnd,
        },
        this.$route.params.produtoId
        // itemsPerPage,
        // this.search,
        // this.options.ordenacao,
        // this.options.desc
      );
    },
  },
};
</script>

<style scoped>
.exibir {
  list-style-type: none;
}
.filtro {
  justify-content: space-around;
}
.como p {
  margin: 6px 1px 0px 8px;
}
.como {
  justify-content: space-between;
}
.font-info {
  font-size: 20px;
}
.benefic {
  margin-right: 1rem;
  padding: 3% !important;
}
.benef {
  border: 3px solid #aaaaaa;
  border-radius: 8px;
  width: 100%;
  color: #aaaaaa;
  margin-top: 1rem;
  font-size: 22px;
}
.benef v-icon {
  color: #aaaaaa !important;
}
.barra {
  border: 1px solid #707070;
}
.bloc-info {
  box-shadow: 0px 6px 16px #00000029;
  padding: 2%;
  margin-bottom: 2rem;
  border-radius: 13px;
  margin-top: 1%;
}
.icon {
  color: #000000 !important;
  background-color: #e8e8e8;
  padding: 12px;
  border-radius: 31px;
}
.btnfiltro {
  background-color: #2b3a8f;
  border: 1px solid #2b3a8f;
}

.v-icon--disabled {
  border: 1px solid #cdcdcd;
}
.como-funciona,
.quadro-branco {
  border-radius: 0.8rem;
}
@media (max-width: 600px) {
  .pacote {
    justify-content: center;
  }

  .pacImage {
    width: 20rem;
  }
}
</style>
